<template>
  <div class="mx-2">
    <el-dropdown @command="selectFilter" :disabled="isDisabled">
      <span class="el-dropdown-link flex items-baseline">
        <span :class="{'text-sm font-bold': isFilterActive }">{{ title }}</span>
        <span class="ml-1 py-0.5 px-1 bg-gray-100 rounded-lg">{{ getFilterLabel( currentFilter ) }}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" :class="popperClass">
        <el-input
          v-if="isFilterable"
          v-model="searchInput"
          size="mini"
          placeholder="Search"
          suffix-icon="el-icon-search"
          clearable
        />
        <template v-for="(filterOption) in filteredOptions" >
          <el-dropdown-item 
            :key="filterOption?.value" 
            :class="{ 'active-date font-medium': filterOption?.selected, 'font-style-italic': filterOption?.value == uuiBlankFilter}" 
            :command="filterOption?.value"
            :divided="filterOption?.value === 'current week'"
          >
            {{ filterOption?.label }}
          </el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "SingleSelectFilter",
  
  props: {
    title: {
      type: String,
      required: true,
      default: 'Filtro'
    },
    type: {
      type: String,
      required: true,
      default: "default"
    },
    options: {
      type: Array,
      require: true,
      default: () => []
    },

    currentFilter: {
      type: String,
      require: true
    },

    isFilterable: {
      type: Boolean,
      default: false
    },
    popperClass: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showIsBlankOption: {
      type: Boolean,
      required: false,
      default: false
    },
    uuiBlankFilter: {
      type: String,
      required: false,
      default: ''
    }
  },
  watch: {
    options() {
      this.handleOptionsList()
    }
  },
  data () {
    return {
      optionsList: [],
      filterDate: [],
      newOptions: [],
      filtersNotApplied: ['all', 'all dates', 'Either Email or SMS Off'],
      optionsLoaded: false,
      searchInput:'',
    }
  },
  computed: {
    isFilterActive() {
      return !this.filtersNotApplied.includes( this.currentFilter ) ;
    },
    filteredOptions() { 
      if (this.searchInput)  
        return this.optionsList.filter(option => option.label.toLowerCase().includes(this.searchInput.toLowerCase()))
      else {
        this.optionsList.forEach(item => {
          if (item.label.toLowerCase() === this.currentFilter.toLowerCase()) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
        return this.optionsList;
      }
    },
    isAllOptionExist(){
      const optionValuesList = this.optionsList.map(item => item.value);
      const allOptionExist = this.filtersNotApplied.some(itemA => optionValuesList.indexOf(itemA) !== -1);
      return allOptionExist;
    },
  },
  mounted() {
    this.handleOptionsList()
  },
  methods: {
    handleOptionsList() {
      this.optionsList = [...this.options]
      if (this.showIsBlankOption) {
        this.optionsList[this.optionsList.length -1 ] = {value: this.uuiBlankFilter, label: `No ${this.title}`}
      } else {
        delete this.optionsList[this.optionsList.length - 1]
      }
    },
    getFilterLabel(currentFilter) {
      if (!currentFilter) return currentFilter;
      const foundOption = this.optionsList.find(o => o.value === currentFilter);
      let selectedLabel = foundOption?.label || currentFilter;
      if (currentFilter == this.uuiBlankFilter) {
        selectedLabel = this.optionsList[this.optionsList.length - 1].label  
      }
      const toCapital = word => word[0].toUpperCase() + word.substr(1);
      return selectedLabel.trim().replace(/\s+/g, ' ').split(' ').map(toCapital).join(' ');
    },

    selectFilter( command ) {
      this.unselectFilterDate();
      const [index, option] = this.getOptionSelected( command );
      this.updateOptionSelected( index, option );
      this.updateOptions();
    },

    unselectFilterDate() {
      const options = this.optionsList.map(option => {
        return {
          ...option,
          selected: false
        }
      })
      this.newOptions = options;
    },

    updateOptionSelected( index, option ) {
      const optionUpdated = {
        ...option,
        selected: true
      };
      const options = [ ...this.newOptions ];
      options[index] = optionUpdated;
      this.newOptions = options;
    },

    getOptionSelected( filter ) {
      const index = this.newOptions.findIndex( option => option.value === filter );
      const option = this.newOptions.find( option => option.value === filter );
      this.filterDate = [ option.value ];
      return [index, option];
    },

    updateOptions() {
      let type = this.type;
      const options = [ ...this.newOptions ];
      const [ currentFilter ] = this.filterDate; 
      
      let indexes = []
      const optionValuesList = this.optionsList.map(item => item.value);
      if (this.isAllOptionExist == true) indexes = [currentFilter].map(item => optionValuesList.indexOf(item) - 1);
      else indexes = [currentFilter].map(item => optionValuesList.indexOf(item));
      const isSameRoute = this.$route.query[type] === indexes.join(',');
      if (!isSameRoute) {
        this.$router.replace({
          params: {
            ...this.$route.params
          },
          query: {
            ...this.$route.query,
            [type]: indexes.join(','),
          },
        });

        this.$emit( 'update-options', { 
          options,
          currentFilter,
          type
        });
        
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.active-date {
  color: #0268BC;
}
.el-input{
  margin: 0 10px 6px 10px;
  width: -webkit-fill-available;
}
.font-style-italic {
  font-style: italic;
}
</style>
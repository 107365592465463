/**
 * IMPORTANT: If you modify this section also consider modifying the other sections: 
 * -> amplify/backend/function/ImportRecords/src/fragments.js (vehicleFragment)
 */
export const vehicleFragment = /* GraphQL */`
  fragment vehicleFragment on Vehicle {
    id
    group
    name
    vehicle
    type
    vehicleType {
      id
      group
      order
      option
      default
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      createdAt
      updatedAt
    }
    labels {
      items {
        id
        labelId
        createdAt
        label {
          id
          name
          status
        }
      }
    }
    state
    licensePlateExp
    dateStart
    dateEnd
    ownership
    provider
    otherProvider
    category
    vin
    make
    model
    year
    rentalAgreementNumber
    images
    rentalContracts
    accidentReports
    licensePlate
    mileage
    gasCard
    status
    company {
      id
      option
    }
    notes
    parkingSpace {
      id
      group
      order
      option
      default
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      createdAt
      updatedAt
    }
    lastOdometerReadingDate
    accidents {
      items{
        id
      }
      nextToken
    }
    vehicleDamage {
      nextToken
    }
    odometerReadings {
      items{
        id
        accidentDate
      }
      nextToken
    }
    maintenance {
      nextToken
    }
    overTenThousandPounds
    customDeliveryVan
    tollPassId
    createdAt
    updatedAt
    device {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      notes
      createdAt
      updatedAt
    }
  }
`;

/**
 * Associate fragment without conexions
 * IMPORTANT: If you modify this section also consider modifying the other sections: 
 * -> amplify/backend/function/ImportRecords/src/fragments.js (associateFragmentWithoutConnections)
 * -> amplify/backend/function/executeMutation/src/graphql/fragments.js (associateFragmentWithoutConnections)
 */
export const associateFragmentWithoutConnections = /* GraphQL */ `
  fragment associateFragmentWithoutConnections on Staff {
    id
    group
    vehicleType
    transporterId
    firstName
    lastName
    alternateNames
    keyFocusArea
    dob
    gender
    hireDate
    phone
    status
    createdAt
    updatedAt
    email
    dlExpiration
    vehicleReport
    gasCardPin
    hireDate
    receiveTextMessages
    receiveEmailMessages
    pronouns
    isALead
    assignedLead
    hourlyStatus
    hireDate
    finalCheckIssueDate
    photo
    smsLastMessageTimestamp
    smsLastMessage
    preferredDaysOff
  }
`;


/**
 * Associate fragment with conexions
 * IMPORTANT: If you modify this section also consider modifying the other sections: 
 * -> amplify/backend/function/ImportRecords/src/fragments.js (associateFragmentWithConnections)
 * -> amplify/backend/function/executeMutation/src/graphql/fragments.js (associateFragmentWithConnections)
 */
export const associateFragmentWithConnections = /* GraphQL */ `
  fragment associateFragmentWithConnections on Staff {
    id
    # alternateNames
    authorizedToDrive {
      items {
        id
        group
        staff {
          id
        }
        optionCustomList {
          id
          option
          pillColor
        }
      }
      nextToken
    }
    defaultVehicle{
      id
      status
      name
    }
    defaultVehicle2 {
      id
      status
      name
    }
    defaultVehicle3 {
      id
      status
      name
    }
    labels {
      items {
        id
        createdAt
        group
        labelId
        label {
          id
          name
          status
        }
      }
      nextToken
    }
    staffStatusHistory{
      items {
        id
        group
        reason
        date
        previousStatus
        currentStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const associateFragment = associateFragmentWithConnections + associateFragmentWithoutConnections + /* GraphQL */ `
  fragment associateFragment on Staff {
    ...associateFragmentWithConnections
    ...associateFragmentWithoutConnections
  }
`;

export const deviceFragment = /* GraphQL */ `
  fragment deviceFragment on Device {
    id
    group
    deviceName
    phoneNumber
    carrier
    imei
    status
    notes
    vehicle {
        items{
            id
        }
      nextToken
    }
    deviceLastMessage
    deviceLastMessageTimestamp
  }
`;

export const userFragment = /* GraphQL */ `
  fragment userFragment on User {
    id
    group
    cognitoSub
    firstName
    lastName
    phone
    email
    emailVerified
    role
    isOwner
    isCreator
    receiveSmsTaskReminders
    receiveEmailTaskReminders
    receiveSmsTaskAssignments
    receiveEmailTaskAssignments
    permissionLogin
    permissionFullAccess
    permissionDocuments
    permissionCounselings
    permissionAccidents
    permissionInjuries
    permissionDrugTests
    permissionDailyRostering
    permissionMessenger
    permissionPerformanceCoaching
    permissionDAManagement
    permissionCustomLists
    permissionManageLabels
    permissionVehicleManagement
    permissionTasksReports
    permissionMessageTemplate
    permissionVehiclePhotoLogs
    lastLogin
    userTenantId
  }
`;

/**
 * IMPORTANT: If you modify this section also consider modifying the other sections: 
 * -> amplify/backend/function/ImportRecords/src/fragments.js (optionsCustomListsStaffFragment)
 */
export const optionsCustomListsStaffFragment = /* GraphQL */ `
  fragment optionsCustomListsStaffFragment on OptionsCustomListsStaff{
    id
    group
    optionCustomList {
      id
      group
      customLists {
        id
        group
        type
        listCategory
        listName
        listDisplay
        createdAt
        updatedAt
      }
      order
      option
    }
    createdAt
    updatedAt
    staff {
      id
    }
  }
`;

/**
 * IMPORTANT: If you modify this section also consider modifying the other sections: 
 * -> amplify/backend/function/ProcessPerformanceData/src/fragments.js (infractionFragment)
 */
export const infractionFragment = /* GraphQL */ `
  fragment infractionFragment on Infraction {
    id
    group
    staffId
    infractionType
    date
    type {
      id
      option
    }
    createdFrom
  }
`;

/**
 * IMPORTANT: If you modify this section also consider modifying the other sections: 
 * -> amplify/backend/function/ProcessPerformanceData/src/fragments.js (kudoFragment)
 */
export const kudoFragment = /* GraphQL */ `
  fragment kudoFragment on Kudo {
    id
    group
    staffId
    kudoType
    date
    type {
      id
      option
    }
    createdFrom
  }
`;

export const tenantFragment = /* GraphQL */ `
  fragment tenantFragment on Tenant {
    id
    group
    coachingDriverRankRange
    coachingFicoIssue
    coachingFicoKudo
    coachingDcrIssue
    coachingDcrKudo
    coachingDarIssue
    coachingDarKudo
    coachingDnrIssue
    coachingDnrKudo
    coachingDsbIssue
    coachingDsbKudo
    coachingSwcPodIssue
    coachingSwcPodKudo
    coachingSwcCcIssue
    coachingSwcCcKudo
    coachingSwcScIssue
    coachingSwcScKudo
    coachingSwcAdIssue
    coachingSwcAdKudo
    coachingSeatbeltOffIssue
    coachingSeatbeltOffKudo
    coachingSpeedingEventIssue
    coachingSpeedingEventKudo
    coachingDistractionsRateIssue
    coachingDistractionsRateKudo
    coachingFollowingDistanceRateIssue
    coachingFollowingDistanceRateKudo
    coachingSignSignalViolationsRateIssue
    coachingSignSignalViolationsRateKudo
    coachingConsecutiveTierIssue
    coachingConsecutiveTierKudo
    coachingOverallTierIssue
    coachingOverallTierKudo
    coachingPositiveFeedbackIssue
    coachingPositiveFeedbackKudo
    coachingCdfScoreIssue
    coachingCdfScoreKudo
    coachingDaTierIssue
    coachingDaTierKudo
    coachingDailyFicoIssue
    coachingDailyFicoKudo
    coachingSeatbeltIssue
    coachingSeatbeltKudo
    coachingSseIssue
    coachingSseKudo
    coachingDvcrsIssue
    coachingTraningRemainingIssue
    coachingCameraObstructionIssue
    coachingDriverDistractionIssue
    coachingDriverDrowsinessIssue
    coachingDriverInitiatedIssue
    coachingDriverStarKudo
    coachingFaceMaskComplianceIssue
    coachingFollowingDistanceIssue
    coachingHardAccelerationIssue
    coachingHardBrakingIssue
    coachingHardTurnIssue
    coachingHighGIssue
    coachingLowImpactIssue
    coachingSeatbeltComplianceIssue
    coachingSignViolationsIssue
    coachingSpeedingViolationsIssue
    coachingTrafficLightViolationIssue
    coachingUTurnIssue
    coachingWeavingIssue
    coachingDailyComplianceRateIssue
    coachingDailyComplianceRateKudo
    permissionHeraAi
    permissionVehiclePhotoLogsHeraAi
    featureAccessVehiclePhotoLogsHeraAi
    featureEnabledVehiclePhotoLogsHeraAi
    featureEnabledInventoryManagement
    featureAccessInventoryManagement
    permissionInventoryManagement
  }
`;

export const tenantUpdateFragment = /* GraphQL */ `
  fragment tenantUpdateFragment on Tenant {
    id
    createdAt
    updatedAt
    companyName
    nameLog
    numberOfSeats
    logo
    accidentReport
    workmansComp
    dispatchNumber
    addressLine1
    addressLine2
    addressCity
    addressState
    addressZip
    group
    zohoCrmAccountRecordId
    originationNumber
    timeZone
    trialExpDate
    firstInterestDateTime
    firstStartedTrialDateTime
    firstConvertedToPaidDateTime
    firstChurnedDateTime
    accountPremiumStatus
    accountCanceledNotes
    accountCanceledReason
    notes
    costStandard
    costBundle
    costPerformance
    costRostering
    costStaff
    costVehicles
    costMessaging
    discountPercent
    discountFixed
    discountPercentLabel
    discountFixedLabel
    flatMonthlyBillingAmount
    flatMonthlyBillingLabel
    payOutstandingByDate
    permissionHeraAi
    permissionVehiclePhotoLogsHeraAi
    featureAccessVehiclePhotoLogsHeraAi
    featureEnabledVehiclePhotoLogsHeraAi
    featureEnabledInventoryManagement
    featureAccessInventoryManagement
    permissionInventoryManagement
    lastPaidPositiveInvoiceLineItemDateTime
    totalNumberOfMonthsPaidByTenant
    lifetimePaymentTotal
    averageMonthlyInvoiceTotal
    isTestingAccount
    calculatedOutstandingBalance
    bundleDeactivationAllowedAt
    performanceDeactivationAllowedAt
    rosteringDeactivationAllowedAt
    staffDeactivationAllowedAt
    vehiclesDeactivationAllowedAt
    automatedCoachingSendTime
    allowLibraryUpload
    coachingCustomMessage
    messageServiceProvider
    OriginationNumberOrderId
    OriginationNumberStatus
    customerStatus
    shortCode
    growSurfParticipantId
    growSurfReferrerEmail
    growSurfReferrerFullName
    growSurfReferredEmail
    growSurfReferredFullName
    growSurfReferralComplete
    growSurfReferredByEmailBackup
    growSurfReferredByFullNameBackup
    growSurfParticipantEmail
    growSurfParticipantFullName
    growSurfParticipantReferralComplete
    stripeCustomerId
    stripeBillingEmail
    driverReportDayRange
    accountType
    customerType
    customerSubType
    accountCountry
    isTemporaryAccount
    allowParentData
    parentAccountId
    parentAccount{
      id
      zohoCrmAccountRecordId
    }
  }
`;
/**
 * IMPORTANT: If you modify this section also consider modifying the other sections: 
 * -> amplify/backend/function/ProcessPerformanceData/src/fragments.js (replaceByRouteFragment)
 */
export const replaceByRouteFragment = /* GraphQL */ `
  fragment replaceByRouteFragment on ReplaceByRoute{
    id
    group
    notes
    routeNumber
    status {
      id
      option
      pillColor
    }
    helperStatus
    parkingSpace {
        id
        order
        option
    }
    staging
    standby
    time
    isNotActive
    messageSentTime
    messageSentError
    receivedAnnouncement
    rescued
    createdAt
    updatedAt
    route{
      id
    }
    dailyRoster{
      id
    }
    staff {
      id
      firstName
      lastName
      receiveEmailMessages
      receiveTextMessages
    }
    document{
      items{
        id
        name
        key
        type
        route{
            id
        }
      }
    }
    vehicle{
        id
        name
    }
    device{
        id
        deviceName
        phoneNumber
        status
    }
    messages {
      items{
        id
        bodyText
        createdAt
        emailStatus
        smsStatus
        smsSendInformation
        emailSendInformation
        messageType
        staff {
            id
            firstName
        }
      }
    }
    replaceByRouteStatusHistory {
      items {
        id
        associateType
        previousStatus {
          id
          option
          pillColor
        }
        currentStatus {
          id
          option
          pillColor
        }
        date
        reason
      }
    }
  }
`;

/**
 * IMPORTANT: If you modify this section also consider modifying the other sections: 
 * -> amplify/backend/function/ProcessPerformanceData/src/fragments.js (routeFragment)
 */
export const routeFragment = replaceByRouteFragment + /* GraphQL */ `
fragment routeFragment on Route{
  id
  routeDailyRosterId
  group
  notes
  routeNumber
  previousStatus
  status {
    id
    option
    pillColor
  }
  helperStatus {
    id
    option
    pillColor
  }
  parkingSpace {
    id
    order
    option
  }
  staging
  standby
  time
  isNotActive
  messageSentTime
  messageSentError
  receivedAnnouncement
  createdAt
  updatedAt
  replaceByRoute (sortDirection: ASC){
    items{
      ...replaceByRouteFragment
    }
  }
  vehicleDamage {
      items {
        id
      }
      nextToken
  }
  staff {
    id
    firstName
    lastName
    status
    receiveEmailMessages
    receiveTextMessages
  }
  helper {
    id
  }
  rescuer{
    id
  }
  rescuers {
    items {
      id
      staff{
        id
      }
    }
  }
  messages {
      items{
      id
      bodyText
      createdAt
      emailStatus
      smsStatus
      smsSendInformation
      emailSendInformation
      messageType
      staff {
          id
          firstName
      }
      }
  }
  vehicle {
      id
  }
  document{
      items{
      id
      name
      key
      type
      route{
          id
      }
      }
  }
  device {
      id
      phoneNumber
      deviceName
      status
  }
  kudos{
      items{
        id
        staffId
      }
  }
  routeStatusHistory {
    items {
      id
      createdAt
      associateType
      previousStatus {
        id
        option
        pillColor
      }
      currentStatus {
        id
        option
        pillColor
      }
      date
      reason
    }
  }
}
`;

export const dailyRosterFragment = routeFragment + /* GraphQL */ `
fragment dailyRosterFragment on DailyRoster{
    id
    group
    notesDate
    lastStandUpSentTime
    createdAt
    updatedAt
    attachment {
      id
      group
      s3Key
      expirationDate
      contentType
      createdAt
      updatedAt
    }
    route {
      items {
        ...routeFragment
      }
      nextToken
    }
    notes {
      items {
        id
        group
        type
        date
        text
      }
    }
}
`;

export const dailyLogFragment = /* GraphQL */ `
fragment dailyLogFragment on DailyLog {
  id
  group
  type
  date
  createdAt
  notes
  vehicleId
  creationLinkSentDateTime
  dailyLogCreationLinkSentByUserId
  dailyLogCreationLinkAssociateId
  dailyLogTakenByUserId
  dailyLogTakenByAssociateId
  dailyLogRosteredDayId
  dailyLogVehicleId
  dailyLogCreatedByUserId
  history {
    items {
      id
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
    }
    nextToken
  }
  shortenUrls {
    items {
      id
      group
      type
      shortenId
      expirationTTL
      urlToken
      attachmentId
      isOpenLink
      createdAt
      updatedAt
    }
    nextToken
  }
  documents {
    items {
      id
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      takenByAssociate{
          id
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}`;

export const accidentFragment = /* GraphQL */ `
fragment accidentFragment on Accident {
  id
  group
  createdAt
  updatedAt
  odometerReadingVehicle{
    id
  }
}`;

export const messageFragment = /* GraphQL */ `
fragment messageFragment on Message {
  id
  group
  createdAt 
  attachment{
    id
    s3Key
    contentType
  }
  sender {
    id
  }
  isReadBy {
    items {
      userID
    }
  }
  channelType
  staffId
  senderName
  bodyText
  isReadS
  messageType
  smsStatus
  smsSendInformation
  staff{
    id
    status
  }
}`;

export const messageReadStatusFragment = /* GraphQL */ `
  fragment messageReadStatusFragment on MessageReadStatus {
    id
    group
    userID
    staffID
    readUpToDateTime
    createdAt
    updatedAt
  }
`;

export const noteFragment = /* GraphQL */ `
  fragment noteFragment on Note {
    id
    group
    type
    date
    text
    vehiclesMentioned {
      nextToken
    }
    staffMentioned {
      nextToken
    }
    createdAt
    updatedAt
    dailyRoster {
      id
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      createdAt
      updatedAt
    }
  }
`;

export const resourceUsageFragment = /* GraphQL */ `
  fragment resourceUsageFragment on ResourceUsage {
    id
    resourceIdentifier
    resourceType
    inUseAsOfDateTime
    userInstanceId
    createdAt
    updatedAt
    user {
      id
      group
      cognitoSub
      firstName
      lastName
    }
  }
`;

export const companyNotificationFragment = /* GraphQL */ `
  fragment companyNotificationFragment on CompanyNotification {
    id
    group
    streamTable
    affectedTable
    message
    expirationTTL
    createdAt
    updatedAt
  }
`;
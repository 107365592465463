/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateTenantById = /* GraphQL */ `
  subscription OnUpdateTenantById($id: ID!) {
    onUpdateTenantById(id: $id) {
      id
      group
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      cards {
        items {
          id
          cardTenantId
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          pickupSuccessBehaviors
          pickupSuccessBehaviorsText
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      textractjobs {
        items {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      waves {
        items {
          id
          waveTenantId
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessage {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      dailyRoster {
        items {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      invoices {
        items {
          id
          invoiceTenantId
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parentAccount {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      users {
        items {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      valueLists {
        items {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      premiumStatusHistory {
        items {
          id
          premiumStatusHistoryTenantId
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageServiceProviderHistory {
        items {
          id
          messageServiceProviderTenantId
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const subscriptionInfractionByGroup = /* GraphQL */ `
  subscription SubscriptionInfractionByGroup($group: String!) {
    subscriptionInfractionByGroup(group: $group) {
      id
      infractionTypeId
      infractionStaffId
      infractionRouteId
      infractionCounselingId
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const subscriptionKudoByGroup = /* GraphQL */ `
  subscription SubscriptionKudoByGroup($group: String!) {
    subscriptionKudoByGroup(group: $group) {
      id
      kudoTypeId
      kudoRouteId
      kudoStaffId
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInfractionByGroup = /* GraphQL */ `
  subscription OnCreateInfractionByGroup($group: String!) {
    onCreateInfractionByGroup(group: $group) {
      id
      infractionTypeId
      infractionStaffId
      infractionRouteId
      infractionCounselingId
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInfractionByGroup = /* GraphQL */ `
  subscription OnUpdateInfractionByGroup($group: String!) {
    onUpdateInfractionByGroup(group: $group) {
      id
      infractionTypeId
      infractionStaffId
      infractionRouteId
      infractionCounselingId
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInfractionByGroup = /* GraphQL */ `
  subscription OnDeleteInfractionByGroup($group: String!) {
    onDeleteInfractionByGroup(group: $group) {
      id
      infractionTypeId
      infractionStaffId
      infractionRouteId
      infractionCounselingId
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateKudoByGroup = /* GraphQL */ `
  subscription OnCreateKudoByGroup($group: String!) {
    onCreateKudoByGroup(group: $group) {
      id
      kudoTypeId
      kudoRouteId
      kudoStaffId
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateKudoByGroup = /* GraphQL */ `
  subscription OnUpdateKudoByGroup($group: String!) {
    onUpdateKudoByGroup(group: $group) {
      id
      kudoTypeId
      kudoRouteId
      kudoStaffId
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteKudoByGroup = /* GraphQL */ `
  subscription OnDeleteKudoByGroup($group: String!) {
    onDeleteKudoByGroup(group: $group) {
      id
      kudoTypeId
      kudoRouteId
      kudoStaffId
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStaffByGroup = /* GraphQL */ `
  subscription OnCreateStaffByGroup($group: String!) {
    onCreateStaffByGroup(group: $group) {
      id
      staffDefaultVehicleId
      staffDefaultVehicle2Id
      staffDefaultVehicle3Id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      drugTests {
        items {
          id
          drugTestStaffId
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      injuries {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      eocScores {
        items {
          id
          textractJobId
          eocScoreStaffId
          group
          date
          level
          average
          averageDailyCompliance
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      counselings {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentionedInNotes {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      onBoarding {
        items {
          id
          onBoardStaffId
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      physicals {
        items {
          id
          physicalStaffId
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      podQualities {
        items {
          id
          podQualityStaffId
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeHelper {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeRescuer {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagePreferencesHistory {
        items {
          id
          messagePreferencesHistoryStaffId
          group
          messagePreferenceType
          description
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cxFeedback {
        items {
          id
          staffCxFeedbackStaffId
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentor {
        items {
          id
          textractJobId
          staffMentorStaffId
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      netrdadyneAlerts {
        items {
          id
          textractJobId
          staffNetradyneAlertStaffId
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scoreCards {
        items {
          id
          staffScoreCardStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffStatusHistory {
        items {
          id
          staffStatusStaffId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      authorizedToDrive {
        items {
          id
          optionsCustomListsStaffStaffId
          optionsCustomListsStaffOptionCustomListId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      uniforms {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle2 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle3 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      routeRescuerStaff {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      properParkingSequences {
        items {
          id
          properParkingSequenceStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStaffByGroup = /* GraphQL */ `
  subscription OnUpdateStaffByGroup($group: String!) {
    onUpdateStaffByGroup(group: $group) {
      id
      staffDefaultVehicleId
      staffDefaultVehicle2Id
      staffDefaultVehicle3Id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      drugTests {
        items {
          id
          drugTestStaffId
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      injuries {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      eocScores {
        items {
          id
          textractJobId
          eocScoreStaffId
          group
          date
          level
          average
          averageDailyCompliance
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      counselings {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentionedInNotes {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      onBoarding {
        items {
          id
          onBoardStaffId
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      physicals {
        items {
          id
          physicalStaffId
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      podQualities {
        items {
          id
          podQualityStaffId
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeHelper {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeRescuer {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagePreferencesHistory {
        items {
          id
          messagePreferencesHistoryStaffId
          group
          messagePreferenceType
          description
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cxFeedback {
        items {
          id
          staffCxFeedbackStaffId
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentor {
        items {
          id
          textractJobId
          staffMentorStaffId
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      netrdadyneAlerts {
        items {
          id
          textractJobId
          staffNetradyneAlertStaffId
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scoreCards {
        items {
          id
          staffScoreCardStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffStatusHistory {
        items {
          id
          staffStatusStaffId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      authorizedToDrive {
        items {
          id
          optionsCustomListsStaffStaffId
          optionsCustomListsStaffOptionCustomListId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      uniforms {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle2 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle3 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      routeRescuerStaff {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      properParkingSequences {
        items {
          id
          properParkingSequenceStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStaffByGroup = /* GraphQL */ `
  subscription OnDeleteStaffByGroup($group: String!) {
    onDeleteStaffByGroup(group: $group) {
      id
      staffDefaultVehicleId
      staffDefaultVehicle2Id
      staffDefaultVehicle3Id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      drugTests {
        items {
          id
          drugTestStaffId
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      injuries {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      eocScores {
        items {
          id
          textractJobId
          eocScoreStaffId
          group
          date
          level
          average
          averageDailyCompliance
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      counselings {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentionedInNotes {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      onBoarding {
        items {
          id
          onBoardStaffId
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      physicals {
        items {
          id
          physicalStaffId
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      podQualities {
        items {
          id
          podQualityStaffId
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeHelper {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeRescuer {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagePreferencesHistory {
        items {
          id
          messagePreferencesHistoryStaffId
          group
          messagePreferenceType
          description
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cxFeedback {
        items {
          id
          staffCxFeedbackStaffId
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentor {
        items {
          id
          textractJobId
          staffMentorStaffId
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      netrdadyneAlerts {
        items {
          id
          textractJobId
          staffNetradyneAlertStaffId
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scoreCards {
        items {
          id
          staffScoreCardStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffStatusHistory {
        items {
          id
          staffStatusStaffId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      authorizedToDrive {
        items {
          id
          optionsCustomListsStaffStaffId
          optionsCustomListsStaffOptionCustomListId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      uniforms {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle2 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle3 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      routeRescuerStaff {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      properParkingSequences {
        items {
          id
          properParkingSequenceStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateVehicleByGroup = /* GraphQL */ `
  subscription OnCreateVehicleByGroup($group: String!) {
    onCreateVehicleByGroup(group: $group) {
      id
      vehicleDeviceId
      vehicleDevice2Id
      vehicleCompanyId
      vehicleParkingSpaceId
      vehicleVehicleTypeId
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceRecords {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      odometerReadings {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      mentionedInNotes {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replacedRoutes {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultStaff {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff2 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff3 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleType {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      company {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicleRecordHistory {
        items {
          id
          vehicleId
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      maintenance {
        items {
          id
          vehicleMaintenanceVehicleId
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      vehicleStatusHistory {
        items {
          id
          vehicleStatusVehicleId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateVehicleByGroup = /* GraphQL */ `
  subscription OnUpdateVehicleByGroup($group: String!) {
    onUpdateVehicleByGroup(group: $group) {
      id
      vehicleDeviceId
      vehicleDevice2Id
      vehicleCompanyId
      vehicleParkingSpaceId
      vehicleVehicleTypeId
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceRecords {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      odometerReadings {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      mentionedInNotes {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replacedRoutes {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultStaff {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff2 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff3 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleType {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      company {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicleRecordHistory {
        items {
          id
          vehicleId
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      maintenance {
        items {
          id
          vehicleMaintenanceVehicleId
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      vehicleStatusHistory {
        items {
          id
          vehicleStatusVehicleId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteVehicleByGroup = /* GraphQL */ `
  subscription OnDeleteVehicleByGroup($group: String!) {
    onDeleteVehicleByGroup(group: $group) {
      id
      vehicleDeviceId
      vehicleDevice2Id
      vehicleCompanyId
      vehicleParkingSpaceId
      vehicleVehicleTypeId
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceRecords {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      odometerReadings {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      mentionedInNotes {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replacedRoutes {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultStaff {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff2 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff3 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleType {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      company {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicleRecordHistory {
        items {
          id
          vehicleId
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      maintenance {
        items {
          id
          vehicleMaintenanceVehicleId
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      vehicleStatusHistory {
        items {
          id
          vehicleStatusVehicleId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDeviceByGroup = /* GraphQL */ `
  subscription OnCreateDeviceByGroup($group: String!) {
    onCreateDeviceByGroup(group: $group) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      deviceLastMessageTimestamp
      deviceLastMessage
      createdAt
      updatedAt
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route2 {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle2 {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDeviceByGroup = /* GraphQL */ `
  subscription OnUpdateDeviceByGroup($group: String!) {
    onUpdateDeviceByGroup(group: $group) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      deviceLastMessageTimestamp
      deviceLastMessage
      createdAt
      updatedAt
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route2 {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle2 {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDeviceByGroup = /* GraphQL */ `
  subscription OnDeleteDeviceByGroup($group: String!) {
    onDeleteDeviceByGroup(group: $group) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      deviceLastMessageTimestamp
      deviceLastMessage
      createdAt
      updatedAt
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route2 {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle2 {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateUserByTenant = /* GraphQL */ `
  subscription OnCreateUserByTenant($userTenantId: ID!) {
    onCreateUserByTenant(userTenantId: $userTenantId) {
      id
      userTenantId
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      zohoCrmContactRecordId
      lastLogin
      injuriesCompletedBy {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assignerTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assigneeTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessagesSent {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidentCreatedBy {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      accidentsVerified {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLogCreatedBy {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      messagesSent {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      resourcesUsage {
        items {
          id
          userInstanceId
          resourceUsageUserId
          resourceIdentifier
          group
          resourceType
          inUseAsOfDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      completedReminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUserByTenant = /* GraphQL */ `
  subscription OnUpdateUserByTenant($userTenantId: ID!) {
    onUpdateUserByTenant(userTenantId: $userTenantId) {
      id
      userTenantId
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      zohoCrmContactRecordId
      lastLogin
      injuriesCompletedBy {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assignerTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assigneeTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessagesSent {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidentCreatedBy {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      accidentsVerified {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLogCreatedBy {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      messagesSent {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      resourcesUsage {
        items {
          id
          userInstanceId
          resourceUsageUserId
          resourceIdentifier
          group
          resourceType
          inUseAsOfDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      completedReminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUserByTenant = /* GraphQL */ `
  subscription OnDeleteUserByTenant($userTenantId: ID!) {
    onDeleteUserByTenant(userTenantId: $userTenantId) {
      id
      userTenantId
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      zohoCrmContactRecordId
      lastLogin
      injuriesCompletedBy {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assignerTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assigneeTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessagesSent {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidentCreatedBy {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      accidentsVerified {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLogCreatedBy {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      messagesSent {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      resourcesUsage {
        items {
          id
          userInstanceId
          resourceUsageUserId
          resourceIdentifier
          group
          resourceType
          inUseAsOfDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      completedReminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateOptionsCustomListsStaffByGroup = /* GraphQL */ `
  subscription OnCreateOptionsCustomListsStaffByGroup($group: String!) {
    onCreateOptionsCustomListsStaffByGroup(group: $group) {
      id
      optionsCustomListsStaffStaffId
      optionsCustomListsStaffOptionCustomListId
      group
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOptionsCustomListsStaffByGroup = /* GraphQL */ `
  subscription OnDeleteOptionsCustomListsStaffByGroup($group: String!) {
    onDeleteOptionsCustomListsStaffByGroup(group: $group) {
      id
      optionsCustomListsStaffStaffId
      optionsCustomListsStaffOptionCustomListId
      group
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessageByGroup = /* GraphQL */ `
  subscription OnCreateMessageByGroup($group: String!) {
    onCreateMessageByGroup(group: $group) {
      id
      senderId
      staffId
      messageRouteId
      messageTenantId
      messageAttachmentId
      messageReplaceByRouteId
      carrierMessageId
      group
      createdAt
      deviceId
      messageType
      channelType
      destinationNumber
      deviceDestinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      deviceSmsStatus
      smsSendInformation
      deviceSmsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      owner
      destinationName
      hasParts
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      sender {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMessageByGroup = /* GraphQL */ `
  subscription OnUpdateMessageByGroup($group: String!) {
    onUpdateMessageByGroup(group: $group) {
      id
      senderId
      staffId
      messageRouteId
      messageTenantId
      messageAttachmentId
      messageReplaceByRouteId
      carrierMessageId
      group
      createdAt
      deviceId
      messageType
      channelType
      destinationNumber
      deviceDestinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      deviceSmsStatus
      smsSendInformation
      deviceSmsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      owner
      destinationName
      hasParts
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      sender {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateRouteByGroup = /* GraphQL */ `
  subscription OnCreateRouteByGroup($group: String!) {
    onCreateRouteByGroup(group: $group) {
      id
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      routeVehicleId
      routeRescuerId
      routeParkingSpaceId
      routeHelperId
      routeDevice2Id
      routeStatusId
      routeHelperStatusId
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      helperUpdatedDateTime
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      helpers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      rescuer {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      helper {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      helperStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      rescuers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRouteByGroup = /* GraphQL */ `
  subscription OnUpdateRouteByGroup($group: String!) {
    onUpdateRouteByGroup(group: $group) {
      id
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      routeVehicleId
      routeRescuerId
      routeParkingSpaceId
      routeHelperId
      routeDevice2Id
      routeStatusId
      routeHelperStatusId
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      helperUpdatedDateTime
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      helpers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      rescuer {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      helper {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      helperStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      rescuers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRouteByGroup = /* GraphQL */ `
  subscription OnDeleteRouteByGroup($group: String!) {
    onDeleteRouteByGroup(group: $group) {
      id
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      routeVehicleId
      routeRescuerId
      routeParkingSpaceId
      routeHelperId
      routeDevice2Id
      routeStatusId
      routeHelperStatusId
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      helperUpdatedDateTime
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      helpers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      rescuer {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      helper {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      helperStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      rescuers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateReplaceByRouteByGroup = /* GraphQL */ `
  subscription OnCreateReplaceByRouteByGroup($group: String!) {
    onCreateReplaceByRouteByGroup(group: $group) {
      id
      replaceByRouteStaffId
      replaceByRouteRouteId
      replaceByRouteStatusId
      replaceByRouteDeviceId
      replaceByRouteVehicleId
      replaceByRouteDailyRosterId
      replaceByRouteParkingSpaceId
      group
      notes
      createdAt
      routeNumber
      staging
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateReplaceByRouteByGroup = /* GraphQL */ `
  subscription OnUpdateReplaceByRouteByGroup($group: String!) {
    onUpdateReplaceByRouteByGroup(group: $group) {
      id
      replaceByRouteStaffId
      replaceByRouteRouteId
      replaceByRouteStatusId
      replaceByRouteDeviceId
      replaceByRouteVehicleId
      replaceByRouteDailyRosterId
      replaceByRouteParkingSpaceId
      group
      notes
      createdAt
      routeNumber
      staging
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteReplaceByRouteByGroup = /* GraphQL */ `
  subscription OnDeleteReplaceByRouteByGroup($group: String!) {
    onDeleteReplaceByRouteByGroup(group: $group) {
      id
      replaceByRouteStaffId
      replaceByRouteRouteId
      replaceByRouteStatusId
      replaceByRouteDeviceId
      replaceByRouteVehicleId
      replaceByRouteDailyRosterId
      replaceByRouteParkingSpaceId
      group
      notes
      createdAt
      routeNumber
      staging
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDailyRosterByGroup = /* GraphQL */ `
  subscription OnUpdateDailyRosterByGroup($group: String!) {
    onUpdateDailyRosterByGroup(group: $group) {
      id
      dailyRosterTenantId
      dailyRosterAttachmentId
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      notes {
        items {
          id
          noteDailyRosterId
          group
          type
          date
          text
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDailyLogByGroup = /* GraphQL */ `
  subscription OnCreateDailyLogByGroup($group: String!) {
    onCreateDailyLogByGroup(group: $group) {
      id
      dailyLogVehicleId
      dailyLogTakenByUserId
      dailyLogRosteredDayId
      dailyLogCreatedByUserId
      dailyLogTakenByAssociateId
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          shortenId
          attachmentId
          shortenUrlDailyLogId
          shortenUrlAttachmentId
          shortenUrlCounselingId
          group
          type
          expirationTTL
          urlToken
          isOpenLink
          urlParameters
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rosteredDay {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      history {
        items {
          id
          dailyLogHistoryDailyLogId
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      creationLinkAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      takenByAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      creationLinkSentByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      takenByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDailyLogByGroup = /* GraphQL */ `
  subscription OnUpdateDailyLogByGroup($group: String!) {
    onUpdateDailyLogByGroup(group: $group) {
      id
      dailyLogVehicleId
      dailyLogTakenByUserId
      dailyLogRosteredDayId
      dailyLogCreatedByUserId
      dailyLogTakenByAssociateId
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          shortenId
          attachmentId
          shortenUrlDailyLogId
          shortenUrlAttachmentId
          shortenUrlCounselingId
          group
          type
          expirationTTL
          urlToken
          isOpenLink
          urlParameters
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rosteredDay {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      history {
        items {
          id
          dailyLogHistoryDailyLogId
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      creationLinkAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      takenByAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      creationLinkSentByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      takenByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDailyLogByGroup = /* GraphQL */ `
  subscription OnDeleteDailyLogByGroup($group: String!) {
    onDeleteDailyLogByGroup(group: $group) {
      id
      dailyLogVehicleId
      dailyLogTakenByUserId
      dailyLogRosteredDayId
      dailyLogCreatedByUserId
      dailyLogTakenByAssociateId
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          shortenId
          attachmentId
          shortenUrlDailyLogId
          shortenUrlAttachmentId
          shortenUrlCounselingId
          group
          type
          expirationTTL
          urlToken
          isOpenLink
          urlParameters
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rosteredDay {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      history {
        items {
          id
          dailyLogHistoryDailyLogId
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      creationLinkAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      takenByAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      creationLinkSentByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      takenByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAccidentByGroup = /* GraphQL */ `
  subscription OnCreateAccidentByGroup($group: String!) {
    onCreateAccidentByGroup(group: $group) {
      id
      accidentVehicleId
      accidentStaffId
      accidentVerifiedById
      accidentMaintenanceVehicleId
      accidentDamageVehicleId
      accidentOdometerReadingVehicleId
      accidentVehicleDamageRouteId
      accidentOptionCustomListId
      accidentCreatedByUserId
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageImages {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageRoute {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      verifiedBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      maintenanceVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      damageVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      odometerReadingVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteAccidentByGroup = /* GraphQL */ `
  subscription OnDeleteAccidentByGroup($group: String!) {
    onDeleteAccidentByGroup(group: $group) {
      id
      accidentVehicleId
      accidentStaffId
      accidentVerifiedById
      accidentMaintenanceVehicleId
      accidentDamageVehicleId
      accidentOdometerReadingVehicleId
      accidentVehicleDamageRouteId
      accidentOptionCustomListId
      accidentCreatedByUserId
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageImages {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageRoute {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      verifiedBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      maintenanceVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      damageVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      odometerReadingVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessageReadStatusByGroup = /* GraphQL */ `
  subscription OnCreateMessageReadStatusByGroup($group: String!) {
    onCreateMessageReadStatusByGroup(group: $group) {
      id
      userID
      staffID
      deviceID
      group
      readUpToDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessageReadStatusByGroup = /* GraphQL */ `
  subscription OnUpdateMessageReadStatusByGroup($group: String!) {
    onUpdateMessageReadStatusByGroup(group: $group) {
      id
      userID
      staffID
      deviceID
      group
      readUpToDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCompanyNotificationByGroup = /* GraphQL */ `
  subscription OnCreateCompanyNotificationByGroup($group: String!) {
    onCreateCompanyNotificationByGroup(group: $group) {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNoteByGroup = /* GraphQL */ `
  subscription OnCreateNoteByGroup($group: String!) {
    onCreateNoteByGroup(group: $group) {
      id
      noteDailyRosterId
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      vehiclesMentioned {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffMentioned {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateNoteByGroup = /* GraphQL */ `
  subscription OnUpdateNoteByGroup($group: String!) {
    onUpdateNoteByGroup(group: $group) {
      id
      noteDailyRosterId
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      vehiclesMentioned {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffMentioned {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateResourceUsageByGroup = /* GraphQL */ `
  subscription OnCreateResourceUsageByGroup($group: String!) {
    onCreateResourceUsageByGroup(group: $group) {
      id
      userInstanceId
      resourceUsageUserId
      resourceIdentifier
      group
      resourceType
      inUseAsOfDateTime
      createdAt
      updatedAt
      user {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateResourceUsageByGroup = /* GraphQL */ `
  subscription OnUpdateResourceUsageByGroup($group: String!) {
    onUpdateResourceUsageByGroup(group: $group) {
      id
      userInstanceId
      resourceUsageUserId
      resourceIdentifier
      group
      resourceType
      inUseAsOfDateTime
      createdAt
      updatedAt
      user {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteResourceUsageByGroup = /* GraphQL */ `
  subscription OnDeleteResourceUsageByGroup($group: String!) {
    onDeleteResourceUsageByGroup(group: $group) {
      id
      userInstanceId
      resourceUsageUserId
      resourceIdentifier
      group
      resourceType
      inUseAsOfDateTime
      createdAt
      updatedAt
      user {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCard = /* GraphQL */ `
  subscription OnCreateCard {
    onCreateCard {
      id
      cardTenantId
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCard = /* GraphQL */ `
  subscription OnUpdateCard {
    onUpdateCard {
      id
      cardTenantId
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCard = /* GraphQL */ `
  subscription OnDeleteCard {
    onDeleteCard {
      id
      cardTenantId
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyScoreCard = /* GraphQL */ `
  subscription OnCreateCompanyScoreCard {
    onCreateCompanyScoreCard {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      pickupSuccessBehaviors
      pickupSuccessBehaviorsText
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCompanyScoreCard = /* GraphQL */ `
  subscription OnUpdateCompanyScoreCard {
    onUpdateCompanyScoreCard {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      pickupSuccessBehaviors
      pickupSuccessBehaviorsText
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCompanyScoreCard = /* GraphQL */ `
  subscription OnDeleteCompanyScoreCard {
    onDeleteCompanyScoreCard {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      pickupSuccessBehaviors
      pickupSuccessBehaviorsText
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCounselingTemplate = /* GraphQL */ `
  subscription OnCreateCounselingTemplate {
    onCreateCounselingTemplate {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCounselingTemplate = /* GraphQL */ `
  subscription OnUpdateCounselingTemplate {
    onUpdateCounselingTemplate {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCounselingTemplate = /* GraphQL */ `
  subscription OnDeleteCounselingTemplate {
    onDeleteCounselingTemplate {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessageTemplate = /* GraphQL */ `
  subscription OnCreateMessageTemplate {
    onCreateMessageTemplate {
      id
      group
      name
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessageTemplate = /* GraphQL */ `
  subscription OnUpdateMessageTemplate {
    onUpdateMessageTemplate {
      id
      group
      name
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessageTemplate = /* GraphQL */ `
  subscription OnDeleteMessageTemplate {
    onDeleteMessageTemplate {
      id
      group
      name
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRecurringMessages = /* GraphQL */ `
  subscription OnCreateRecurringMessages {
    onCreateRecurringMessages {
      id
      senderId
      recurringMessagesTenantId
      group
      messageName
      messageBody
      sendTime
      channelType
      senderName
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      sender {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRecurringMessages = /* GraphQL */ `
  subscription OnUpdateRecurringMessages {
    onUpdateRecurringMessages {
      id
      senderId
      recurringMessagesTenantId
      group
      messageName
      messageBody
      sendTime
      channelType
      senderName
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      sender {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRecurringMessages = /* GraphQL */ `
  subscription OnDeleteRecurringMessages {
    onDeleteRecurringMessages {
      id
      senderId
      recurringMessagesTenantId
      group
      messageName
      messageBody
      sendTime
      channelType
      senderName
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      sender {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDrugTest = /* GraphQL */ `
  subscription OnCreateDrugTest {
    onCreateDrugTest {
      id
      drugTestStaffId
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDrugTest = /* GraphQL */ `
  subscription OnUpdateDrugTest {
    onUpdateDrugTest {
      id
      drugTestStaffId
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDrugTest = /* GraphQL */ `
  subscription OnDeleteDrugTest {
    onDeleteDrugTest {
      id
      drugTestStaffId
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInjury = /* GraphQL */ `
  subscription OnCreateInjury {
    onCreateInjury {
      id
      injuryStaffId
      injuryCompletedByUserId
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      completedByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInjury = /* GraphQL */ `
  subscription OnUpdateInjury {
    onUpdateInjury {
      id
      injuryStaffId
      injuryCompletedByUserId
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      completedByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInjury = /* GraphQL */ `
  subscription OnDeleteInjury {
    onDeleteInjury {
      id
      injuryStaffId
      injuryCompletedByUserId
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      completedByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePendingMessage = /* GraphQL */ `
  subscription OnCreatePendingMessage {
    onCreatePendingMessage {
      id
      staffId
      pendingMessageTenantId
      group
      createdAt
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePendingMessage = /* GraphQL */ `
  subscription OnUpdatePendingMessage {
    onUpdatePendingMessage {
      id
      staffId
      pendingMessageTenantId
      group
      createdAt
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePendingMessage = /* GraphQL */ `
  subscription OnDeletePendingMessage {
    onDeletePendingMessage {
      id
      staffId
      pendingMessageTenantId
      group
      createdAt
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateReplacedByStandbyByRoute = /* GraphQL */ `
  subscription OnCreateReplacedByStandbyByRoute {
    onCreateReplacedByStandbyByRoute {
      id
      replacedByStandbyByRouteStatusId
      replacedByStandbyByRouteRouteId
      replacedByStandbyByRouteDailyRosterId
      replacedByStandbyByRouteStaffId
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateReplacedByStandbyByRoute = /* GraphQL */ `
  subscription OnUpdateReplacedByStandbyByRoute {
    onUpdateReplacedByStandbyByRoute {
      id
      replacedByStandbyByRouteStatusId
      replacedByStandbyByRouteRouteId
      replacedByStandbyByRouteDailyRosterId
      replacedByStandbyByRouteStaffId
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteReplacedByStandbyByRoute = /* GraphQL */ `
  subscription OnDeleteReplacedByStandbyByRoute {
    onDeleteReplacedByStandbyByRoute {
      id
      replacedByStandbyByRouteStatusId
      replacedByStandbyByRouteRouteId
      replacedByStandbyByRouteDailyRosterId
      replacedByStandbyByRouteStaffId
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSystem = /* GraphQL */ `
  subscription OnCreateSystem {
    onCreateSystem {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSystem = /* GraphQL */ `
  subscription OnUpdateSystem {
    onUpdateSystem {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSystem = /* GraphQL */ `
  subscription OnDeleteSystem {
    onDeleteSystem {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTask = /* GraphQL */ `
  subscription OnCreateTask($owner: String) {
    onCreateTask(owner: $owner) {
      id
      taskAssigneeId
      taskAssignerId
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigner {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      assignee {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTask = /* GraphQL */ `
  subscription OnUpdateTask($owner: String) {
    onUpdateTask(owner: $owner) {
      id
      taskAssigneeId
      taskAssignerId
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigner {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      assignee {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTask = /* GraphQL */ `
  subscription OnDeleteTask($owner: String) {
    onDeleteTask(owner: $owner) {
      id
      taskAssigneeId
      taskAssignerId
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigner {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      assignee {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDailyRostersColumns = /* GraphQL */ `
  subscription OnCreateDailyRostersColumns {
    onCreateDailyRostersColumns {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDailyRostersColumns = /* GraphQL */ `
  subscription OnUpdateDailyRostersColumns {
    onUpdateDailyRostersColumns {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDailyRostersColumns = /* GraphQL */ `
  subscription OnDeleteDailyRostersColumns {
    onDeleteDailyRostersColumns {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTextractJob = /* GraphQL */ `
  subscription OnCreateTextractJob($owner: String) {
    onCreateTextractJob(owner: $owner) {
      id
      textractJobTenantId
      group
      owner
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        podQualitySummaryTextractJobId
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      cxFeedbackSummary {
        id
        cxFeedbackSummaryTextractJobId
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      properParkingSequenceSummary {
        id
        properParkingSequenceSummaryTextractJobId
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTextractJob = /* GraphQL */ `
  subscription OnUpdateTextractJob($owner: String) {
    onUpdateTextractJob(owner: $owner) {
      id
      textractJobTenantId
      group
      owner
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        podQualitySummaryTextractJobId
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      cxFeedbackSummary {
        id
        cxFeedbackSummaryTextractJobId
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      properParkingSequenceSummary {
        id
        properParkingSequenceSummaryTextractJobId
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTextractJob = /* GraphQL */ `
  subscription OnDeleteTextractJob($owner: String) {
    onDeleteTextractJob(owner: $owner) {
      id
      textractJobTenantId
      group
      owner
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        podQualitySummaryTextractJobId
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      cxFeedbackSummary {
        id
        cxFeedbackSummaryTextractJobId
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      properParkingSequenceSummary {
        id
        properParkingSequenceSummaryTextractJobId
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePodQualitySummary = /* GraphQL */ `
  subscription OnCreatePodQualitySummary {
    onCreatePodQualitySummary {
      id
      podQualitySummaryTextractJobId
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        textractJobTenantId
        group
        owner
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          podQualitySummaryTextractJobId
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        cxFeedbackSummary {
          id
          cxFeedbackSummaryTextractJobId
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        properParkingSequenceSummary {
          id
          properParkingSequenceSummaryTextractJobId
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePodQualitySummary = /* GraphQL */ `
  subscription OnUpdatePodQualitySummary {
    onUpdatePodQualitySummary {
      id
      podQualitySummaryTextractJobId
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        textractJobTenantId
        group
        owner
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          podQualitySummaryTextractJobId
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        cxFeedbackSummary {
          id
          cxFeedbackSummaryTextractJobId
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        properParkingSequenceSummary {
          id
          properParkingSequenceSummaryTextractJobId
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePodQualitySummary = /* GraphQL */ `
  subscription OnDeletePodQualitySummary {
    onDeletePodQualitySummary {
      id
      podQualitySummaryTextractJobId
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        textractJobTenantId
        group
        owner
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          podQualitySummaryTextractJobId
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        cxFeedbackSummary {
          id
          cxFeedbackSummaryTextractJobId
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        properParkingSequenceSummary {
          id
          properParkingSequenceSummaryTextractJobId
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCxFeedbackSummary = /* GraphQL */ `
  subscription OnCreateCxFeedbackSummary {
    onCreateCxFeedbackSummary {
      id
      cxFeedbackSummaryTextractJobId
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        textractJobTenantId
        group
        owner
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          podQualitySummaryTextractJobId
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        cxFeedbackSummary {
          id
          cxFeedbackSummaryTextractJobId
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        properParkingSequenceSummary {
          id
          properParkingSequenceSummaryTextractJobId
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCxFeedbackSummary = /* GraphQL */ `
  subscription OnUpdateCxFeedbackSummary {
    onUpdateCxFeedbackSummary {
      id
      cxFeedbackSummaryTextractJobId
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        textractJobTenantId
        group
        owner
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          podQualitySummaryTextractJobId
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        cxFeedbackSummary {
          id
          cxFeedbackSummaryTextractJobId
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        properParkingSequenceSummary {
          id
          properParkingSequenceSummaryTextractJobId
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCxFeedbackSummary = /* GraphQL */ `
  subscription OnDeleteCxFeedbackSummary {
    onDeleteCxFeedbackSummary {
      id
      cxFeedbackSummaryTextractJobId
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        textractJobTenantId
        group
        owner
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          podQualitySummaryTextractJobId
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        cxFeedbackSummary {
          id
          cxFeedbackSummaryTextractJobId
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        properParkingSequenceSummary {
          id
          properParkingSequenceSummaryTextractJobId
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateWave = /* GraphQL */ `
  subscription OnCreateWave {
    onCreateWave {
      id
      waveTenantId
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateWave = /* GraphQL */ `
  subscription OnUpdateWave {
    onUpdateWave {
      id
      waveTenantId
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteWave = /* GraphQL */ `
  subscription OnDeleteWave {
    onDeleteWave {
      id
      waveTenantId
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateRouteStaffHelper = /* GraphQL */ `
  subscription OnCreateRouteStaffHelper {
    onCreateRouteStaffHelper {
      id
      routeId
      staffId
      group
      startTime
      endTime
      status
      daIssueCreated
      counselingCreated
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRouteStaffHelper = /* GraphQL */ `
  subscription OnUpdateRouteStaffHelper {
    onUpdateRouteStaffHelper {
      id
      routeId
      staffId
      group
      startTime
      endTime
      status
      daIssueCreated
      counselingCreated
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRouteStaffHelper = /* GraphQL */ `
  subscription OnDeleteRouteStaffHelper {
    onDeleteRouteStaffHelper {
      id
      routeId
      staffId
      group
      startTime
      endTime
      status
      daIssueCreated
      counselingCreated
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStripeQueue = /* GraphQL */ `
  subscription OnCreateStripeQueue($owner: String!) {
    onCreateStripeQueue(owner: $owner) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateStripeQueue = /* GraphQL */ `
  subscription OnUpdateStripeQueue($owner: String!) {
    onUpdateStripeQueue(owner: $owner) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteStripeQueue = /* GraphQL */ `
  subscription OnDeleteStripeQueue($owner: String!) {
    onDeleteStripeQueue(owner: $owner) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateAuditLogArchive = /* GraphQL */ `
  subscription OnCreateAuditLogArchive {
    onCreateAuditLogArchive {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      auditLogCreatedAt
      createdAt
      ipAddress
      pageUrl
      userType
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAuditLogArchive = /* GraphQL */ `
  subscription OnUpdateAuditLogArchive {
    onUpdateAuditLogArchive {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      auditLogCreatedAt
      createdAt
      ipAddress
      pageUrl
      userType
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAuditLogArchive = /* GraphQL */ `
  subscription OnDeleteAuditLogArchive {
    onDeleteAuditLogArchive {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      auditLogCreatedAt
      createdAt
      ipAddress
      pageUrl
      userType
      updatedAt
      __typename
    }
  }
`;
export const onCreateNickname = /* GraphQL */ `
  subscription OnCreateNickname {
    onCreateNickname {
      id
      name
      nicknames
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNickname = /* GraphQL */ `
  subscription OnUpdateNickname {
    onUpdateNickname {
      id
      name
      nicknames
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNickname = /* GraphQL */ `
  subscription OnDeleteNickname {
    onDeleteNickname {
      id
      name
      nicknames
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAccident = /* GraphQL */ `
  subscription OnCreateAccident {
    onCreateAccident {
      id
      accidentVehicleId
      accidentStaffId
      accidentVerifiedById
      accidentMaintenanceVehicleId
      accidentDamageVehicleId
      accidentOdometerReadingVehicleId
      accidentVehicleDamageRouteId
      accidentOptionCustomListId
      accidentCreatedByUserId
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageImages {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageRoute {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      verifiedBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      maintenanceVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      damageVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      odometerReadingVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateAccident = /* GraphQL */ `
  subscription OnUpdateAccident {
    onUpdateAccident {
      id
      accidentVehicleId
      accidentStaffId
      accidentVerifiedById
      accidentMaintenanceVehicleId
      accidentDamageVehicleId
      accidentOdometerReadingVehicleId
      accidentVehicleDamageRouteId
      accidentOptionCustomListId
      accidentCreatedByUserId
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageImages {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageRoute {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      verifiedBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      maintenanceVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      damageVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      odometerReadingVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteAccident = /* GraphQL */ `
  subscription OnDeleteAccident {
    onDeleteAccident {
      id
      accidentVehicleId
      accidentStaffId
      accidentVerifiedById
      accidentMaintenanceVehicleId
      accidentDamageVehicleId
      accidentOdometerReadingVehicleId
      accidentVehicleDamageRouteId
      accidentOptionCustomListId
      accidentCreatedByUserId
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageImages {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceDocuments {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamageRoute {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      verifiedBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      maintenanceVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      damageVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      odometerReadingVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment {
    onCreateAttachment {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          shortenId
          attachmentId
          shortenUrlDailyLogId
          shortenUrlAttachmentId
          shortenUrlCounselingId
          group
          type
          expirationTTL
          urlToken
          isOpenLink
          urlParameters
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dailyRoster {
        items {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      message {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment {
    onUpdateAttachment {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          shortenId
          attachmentId
          shortenUrlDailyLogId
          shortenUrlAttachmentId
          shortenUrlCounselingId
          group
          type
          expirationTTL
          urlToken
          isOpenLink
          urlParameters
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dailyRoster {
        items {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      message {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment {
    onDeleteAttachment {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          shortenId
          attachmentId
          shortenUrlDailyLogId
          shortenUrlAttachmentId
          shortenUrlCounselingId
          group
          type
          expirationTTL
          urlToken
          isOpenLink
          urlParameters
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dailyRoster {
        items {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      message {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateEocScore = /* GraphQL */ `
  subscription OnCreateEocScore {
    onCreateEocScore {
      id
      textractJobId
      eocScoreStaffId
      group
      date
      level
      average
      averageDailyCompliance
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateEocScore = /* GraphQL */ `
  subscription OnUpdateEocScore {
    onUpdateEocScore {
      id
      textractJobId
      eocScoreStaffId
      group
      date
      level
      average
      averageDailyCompliance
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteEocScore = /* GraphQL */ `
  subscription OnDeleteEocScore {
    onDeleteEocScore {
      id
      textractJobId
      eocScoreStaffId
      group
      date
      level
      average
      averageDailyCompliance
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCounseling = /* GraphQL */ `
  subscription OnCreateCounseling {
    onCreateCounseling {
      id
      counselingUserId
      counselingStaffId
      counselingSeverityId
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      counselingTypeId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      severity {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      user {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCounseling = /* GraphQL */ `
  subscription OnUpdateCounseling {
    onUpdateCounseling {
      id
      counselingUserId
      counselingStaffId
      counselingSeverityId
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      counselingTypeId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      severity {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      user {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCounseling = /* GraphQL */ `
  subscription OnDeleteCounseling {
    onDeleteCounseling {
      id
      counselingUserId
      counselingStaffId
      counselingSeverityId
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      counselingTypeId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      severity {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      user {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateShortenUrl = /* GraphQL */ `
  subscription OnCreateShortenUrl {
    onCreateShortenUrl {
      id
      shortenId
      attachmentId
      shortenUrlDailyLogId
      shortenUrlAttachmentId
      shortenUrlCounselingId
      group
      type
      expirationTTL
      urlToken
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLog {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateShortenUrl = /* GraphQL */ `
  subscription OnUpdateShortenUrl {
    onUpdateShortenUrl {
      id
      shortenId
      attachmentId
      shortenUrlDailyLogId
      shortenUrlAttachmentId
      shortenUrlCounselingId
      group
      type
      expirationTTL
      urlToken
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLog {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteShortenUrl = /* GraphQL */ `
  subscription OnDeleteShortenUrl {
    onDeleteShortenUrl {
      id
      shortenId
      attachmentId
      shortenUrlDailyLogId
      shortenUrlAttachmentId
      shortenUrlCounselingId
      group
      type
      expirationTTL
      urlToken
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLog {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDailyRoster = /* GraphQL */ `
  subscription OnCreateDailyRoster {
    onCreateDailyRoster {
      id
      dailyRosterTenantId
      dailyRosterAttachmentId
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      notes {
        items {
          id
          noteDailyRosterId
          group
          type
          date
          text
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDailyRoster = /* GraphQL */ `
  subscription OnUpdateDailyRoster {
    onUpdateDailyRoster {
      id
      dailyRosterTenantId
      dailyRosterAttachmentId
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      notes {
        items {
          id
          noteDailyRosterId
          group
          type
          date
          text
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDailyRoster = /* GraphQL */ `
  subscription OnDeleteDailyRoster {
    onDeleteDailyRoster {
      id
      dailyRosterTenantId
      dailyRosterAttachmentId
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      notes {
        items {
          id
          noteDailyRosterId
          group
          type
          date
          text
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      deviceLastMessageTimestamp
      deviceLastMessage
      createdAt
      updatedAt
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route2 {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle2 {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      deviceLastMessageTimestamp
      deviceLastMessage
      createdAt
      updatedAt
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route2 {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle2 {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      deviceLastMessageTimestamp
      deviceLastMessage
      createdAt
      updatedAt
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route2 {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicle2 {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDailyLog = /* GraphQL */ `
  subscription OnCreateDailyLog {
    onCreateDailyLog {
      id
      dailyLogVehicleId
      dailyLogTakenByUserId
      dailyLogRosteredDayId
      dailyLogCreatedByUserId
      dailyLogTakenByAssociateId
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          shortenId
          attachmentId
          shortenUrlDailyLogId
          shortenUrlAttachmentId
          shortenUrlCounselingId
          group
          type
          expirationTTL
          urlToken
          isOpenLink
          urlParameters
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rosteredDay {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      history {
        items {
          id
          dailyLogHistoryDailyLogId
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      creationLinkAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      takenByAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      creationLinkSentByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      takenByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDailyLog = /* GraphQL */ `
  subscription OnUpdateDailyLog {
    onUpdateDailyLog {
      id
      dailyLogVehicleId
      dailyLogTakenByUserId
      dailyLogRosteredDayId
      dailyLogCreatedByUserId
      dailyLogTakenByAssociateId
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          shortenId
          attachmentId
          shortenUrlDailyLogId
          shortenUrlAttachmentId
          shortenUrlCounselingId
          group
          type
          expirationTTL
          urlToken
          isOpenLink
          urlParameters
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rosteredDay {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      history {
        items {
          id
          dailyLogHistoryDailyLogId
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      creationLinkAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      takenByAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      creationLinkSentByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      takenByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDailyLog = /* GraphQL */ `
  subscription OnDeleteDailyLog {
    onDeleteDailyLog {
      id
      dailyLogVehicleId
      dailyLogTakenByUserId
      dailyLogRosteredDayId
      dailyLogCreatedByUserId
      dailyLogTakenByAssociateId
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          shortenId
          attachmentId
          shortenUrlDailyLogId
          shortenUrlAttachmentId
          shortenUrlCounselingId
          group
          type
          expirationTTL
          urlToken
          isOpenLink
          urlParameters
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rosteredDay {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      history {
        items {
          id
          dailyLogHistoryDailyLogId
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      creationLinkAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      takenByAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      creationLinkSentByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      takenByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      createdByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDailyLogHistory = /* GraphQL */ `
  subscription OnCreateDailyLogHistory {
    onCreateDailyLogHistory {
      id
      dailyLogHistoryDailyLogId
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDailyLogHistory = /* GraphQL */ `
  subscription OnUpdateDailyLogHistory {
    onUpdateDailyLogHistory {
      id
      dailyLogHistoryDailyLogId
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDailyLogHistory = /* GraphQL */ `
  subscription OnDeleteDailyLogHistory {
    onDeleteDailyLogHistory {
      id
      dailyLogHistoryDailyLogId
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument {
    onCreateDocument {
      id
      documentStaffId
      documentRouteId
      documentVehicleId
      documentIncidentId
      documentInfractionId
      documentImageCounselingId
      documentImagevehicleDamageId
      documentOptionCustomListsId
      documentMaintenanceId
      documentReplaceByRouteId
      documentInjuryId
      documentDailyLogId
      documentTakenByUserId
      documentTakenByAssociateId
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      injury {
        id
        injuryStaffId
        injuryCompletedByUserId
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        completedByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      incident {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      imagevehicleDamage {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      maintenance {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      imageCounseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLog {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      infraction {
        id
        infractionTypeId
        infractionStaffId
        infractionRouteId
        infractionCounselingId
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      takenByAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomLists {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      takenByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument {
    onUpdateDocument {
      id
      documentStaffId
      documentRouteId
      documentVehicleId
      documentIncidentId
      documentInfractionId
      documentImageCounselingId
      documentImagevehicleDamageId
      documentOptionCustomListsId
      documentMaintenanceId
      documentReplaceByRouteId
      documentInjuryId
      documentDailyLogId
      documentTakenByUserId
      documentTakenByAssociateId
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      injury {
        id
        injuryStaffId
        injuryCompletedByUserId
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        completedByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      incident {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      imagevehicleDamage {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      maintenance {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      imageCounseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLog {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      infraction {
        id
        infractionTypeId
        infractionStaffId
        infractionRouteId
        infractionCounselingId
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      takenByAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomLists {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      takenByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument {
    onDeleteDocument {
      id
      documentStaffId
      documentRouteId
      documentVehicleId
      documentIncidentId
      documentInfractionId
      documentImageCounselingId
      documentImagevehicleDamageId
      documentOptionCustomListsId
      documentMaintenanceId
      documentReplaceByRouteId
      documentInjuryId
      documentDailyLogId
      documentTakenByUserId
      documentTakenByAssociateId
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      injury {
        id
        injuryStaffId
        injuryCompletedByUserId
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        completedByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      incident {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      imagevehicleDamage {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      maintenance {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      imageCounseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLog {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      infraction {
        id
        infractionTypeId
        infractionStaffId
        infractionRouteId
        infractionCounselingId
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      takenByAssociate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomLists {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      takenByUser {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInfraction = /* GraphQL */ `
  subscription OnCreateInfraction {
    onCreateInfraction {
      id
      infractionTypeId
      infractionStaffId
      infractionRouteId
      infractionCounselingId
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInfraction = /* GraphQL */ `
  subscription OnUpdateInfraction {
    onUpdateInfraction {
      id
      infractionTypeId
      infractionStaffId
      infractionRouteId
      infractionCounselingId
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInfraction = /* GraphQL */ `
  subscription OnDeleteInfraction {
    onDeleteInfraction {
      id
      infractionTypeId
      infractionStaffId
      infractionRouteId
      infractionCounselingId
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice {
    onCreateInvoice {
      id
      invoiceTenantId
      createdAt
      group
      month
      year
      invoiceTotal
      variableTotal
      invoiceSubTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      paidByCustomerAmount
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceLineItemInvoiceId
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice {
    onUpdateInvoice {
      id
      invoiceTenantId
      createdAt
      group
      month
      year
      invoiceTotal
      variableTotal
      invoiceSubTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      paidByCustomerAmount
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceLineItemInvoiceId
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice {
    onDeleteInvoice {
      id
      invoiceTenantId
      createdAt
      group
      month
      year
      invoiceTotal
      variableTotal
      invoiceSubTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      paidByCustomerAmount
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceLineItemInvoiceId
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInvoiceLineItem = /* GraphQL */ `
  subscription OnCreateInvoiceLineItem {
    onCreateInvoiceLineItem {
      id
      invoiceLineItemInvoiceId
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        invoiceTenantId
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInvoiceLineItem = /* GraphQL */ `
  subscription OnUpdateInvoiceLineItem {
    onUpdateInvoiceLineItem {
      id
      invoiceLineItemInvoiceId
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        invoiceTenantId
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInvoiceLineItem = /* GraphQL */ `
  subscription OnDeleteInvoiceLineItem {
    onDeleteInvoiceLineItem {
      id
      invoiceLineItemInvoiceId
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        invoiceTenantId
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateKudo = /* GraphQL */ `
  subscription OnCreateKudo {
    onCreateKudo {
      id
      kudoTypeId
      kudoRouteId
      kudoStaffId
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateKudo = /* GraphQL */ `
  subscription OnUpdateKudo {
    onUpdateKudo {
      id
      kudoTypeId
      kudoRouteId
      kudoStaffId
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteKudo = /* GraphQL */ `
  subscription OnDeleteKudo {
    onDeleteKudo {
      id
      kudoTypeId
      kudoRouteId
      kudoStaffId
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      type {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($owner: String) {
    onCreateMessage(owner: $owner) {
      id
      senderId
      staffId
      messageRouteId
      messageTenantId
      messageAttachmentId
      messageReplaceByRouteId
      carrierMessageId
      group
      createdAt
      deviceId
      messageType
      channelType
      destinationNumber
      deviceDestinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      deviceSmsStatus
      smsSendInformation
      deviceSmsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      owner
      destinationName
      hasParts
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      sender {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($owner: String) {
    onUpdateMessage(owner: $owner) {
      id
      senderId
      staffId
      messageRouteId
      messageTenantId
      messageAttachmentId
      messageReplaceByRouteId
      carrierMessageId
      group
      createdAt
      deviceId
      messageType
      channelType
      destinationNumber
      deviceDestinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      deviceSmsStatus
      smsSendInformation
      deviceSmsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      owner
      destinationName
      hasParts
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      sender {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($owner: String) {
    onDeleteMessage(owner: $owner) {
      id
      senderId
      staffId
      messageRouteId
      messageTenantId
      messageAttachmentId
      messageReplaceByRouteId
      carrierMessageId
      group
      createdAt
      deviceId
      messageType
      channelType
      destinationNumber
      deviceDestinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      deviceSmsStatus
      smsSendInformation
      deviceSmsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      owner
      destinationName
      hasParts
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
          __typename
        }
        dailyRoster {
          nextToken
          __typename
        }
        message {
          nextToken
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      sender {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateResourceUsage = /* GraphQL */ `
  subscription OnCreateResourceUsage {
    onCreateResourceUsage {
      id
      userInstanceId
      resourceUsageUserId
      resourceIdentifier
      group
      resourceType
      inUseAsOfDateTime
      createdAt
      updatedAt
      user {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateResourceUsage = /* GraphQL */ `
  subscription OnUpdateResourceUsage {
    onUpdateResourceUsage {
      id
      userInstanceId
      resourceUsageUserId
      resourceIdentifier
      group
      resourceType
      inUseAsOfDateTime
      createdAt
      updatedAt
      user {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteResourceUsage = /* GraphQL */ `
  subscription OnDeleteResourceUsage {
    onDeleteResourceUsage {
      id
      userInstanceId
      resourceUsageUserId
      resourceIdentifier
      group
      resourceType
      inUseAsOfDateTime
      createdAt
      updatedAt
      user {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote {
    onCreateNote {
      id
      noteDailyRosterId
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      vehiclesMentioned {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffMentioned {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateNote = /* GraphQL */ `
  subscription OnUpdateNote {
    onUpdateNote {
      id
      noteDailyRosterId
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      vehiclesMentioned {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffMentioned {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote {
    onDeleteNote {
      id
      noteDailyRosterId
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      vehiclesMentioned {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffMentioned {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateVehicleMention = /* GraphQL */ `
  subscription OnCreateVehicleMention {
    onCreateVehicleMention {
      id
      vehicleMentionNoteId
      vehicleMentionVehicleId
      group
      date
      createdAt
      updatedAt
      note {
        id
        noteDailyRosterId
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        vehiclesMentioned {
          nextToken
          __typename
        }
        staffMentioned {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateVehicleMention = /* GraphQL */ `
  subscription OnUpdateVehicleMention {
    onUpdateVehicleMention {
      id
      vehicleMentionNoteId
      vehicleMentionVehicleId
      group
      date
      createdAt
      updatedAt
      note {
        id
        noteDailyRosterId
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        vehiclesMentioned {
          nextToken
          __typename
        }
        staffMentioned {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteVehicleMention = /* GraphQL */ `
  subscription OnDeleteVehicleMention {
    onDeleteVehicleMention {
      id
      vehicleMentionNoteId
      vehicleMentionVehicleId
      group
      date
      createdAt
      updatedAt
      note {
        id
        noteDailyRosterId
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        vehiclesMentioned {
          nextToken
          __typename
        }
        staffMentioned {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStaffMention = /* GraphQL */ `
  subscription OnCreateStaffMention {
    onCreateStaffMention {
      id
      staffMentionNoteId
      staffMentionStaffId
      group
      date
      createdAt
      updatedAt
      note {
        id
        noteDailyRosterId
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        vehiclesMentioned {
          nextToken
          __typename
        }
        staffMentioned {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStaffMention = /* GraphQL */ `
  subscription OnUpdateStaffMention {
    onUpdateStaffMention {
      id
      staffMentionNoteId
      staffMentionStaffId
      group
      date
      createdAt
      updatedAt
      note {
        id
        noteDailyRosterId
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        vehiclesMentioned {
          nextToken
          __typename
        }
        staffMentioned {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStaffMention = /* GraphQL */ `
  subscription OnDeleteStaffMention {
    onDeleteStaffMention {
      id
      staffMentionNoteId
      staffMentionStaffId
      group
      date
      createdAt
      updatedAt
      note {
        id
        noteDailyRosterId
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        vehiclesMentioned {
          nextToken
          __typename
        }
        staffMentioned {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($owner: String) {
    onCreateNotification(owner: $owner) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification($owner: String) {
    onUpdateNotification(owner: $owner) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification($owner: String) {
    onDeleteNotification(owner: $owner) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
      __typename
    }
  }
`;
export const onCreateCompanyNotification = /* GraphQL */ `
  subscription OnCreateCompanyNotification {
    onCreateCompanyNotification {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCompanyNotification = /* GraphQL */ `
  subscription OnUpdateCompanyNotification {
    onUpdateCompanyNotification {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCompanyNotification = /* GraphQL */ `
  subscription OnDeleteCompanyNotification {
    onDeleteCompanyNotification {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOnBoard = /* GraphQL */ `
  subscription OnCreateOnBoard {
    onCreateOnBoard {
      id
      onBoardStaffId
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOnBoard = /* GraphQL */ `
  subscription OnUpdateOnBoard {
    onUpdateOnBoard {
      id
      onBoardStaffId
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOnBoard = /* GraphQL */ `
  subscription OnDeleteOnBoard {
    onDeleteOnBoard {
      id
      onBoardStaffId
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePhysical = /* GraphQL */ `
  subscription OnCreatePhysical {
    onCreatePhysical {
      id
      physicalStaffId
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePhysical = /* GraphQL */ `
  subscription OnUpdatePhysical {
    onUpdatePhysical {
      id
      physicalStaffId
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePhysical = /* GraphQL */ `
  subscription OnDeletePhysical {
    onDeletePhysical {
      id
      physicalStaffId
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePodQuality = /* GraphQL */ `
  subscription OnCreatePodQuality {
    onCreatePodQuality {
      id
      podQualityStaffId
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePodQuality = /* GraphQL */ `
  subscription OnUpdatePodQuality {
    onUpdatePodQuality {
      id
      podQualityStaffId
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePodQuality = /* GraphQL */ `
  subscription OnDeletePodQuality {
    onDeletePodQuality {
      id
      podQualityStaffId
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateReplaceByRoute = /* GraphQL */ `
  subscription OnCreateReplaceByRoute {
    onCreateReplaceByRoute {
      id
      replaceByRouteStaffId
      replaceByRouteRouteId
      replaceByRouteStatusId
      replaceByRouteDeviceId
      replaceByRouteVehicleId
      replaceByRouteDailyRosterId
      replaceByRouteParkingSpaceId
      group
      notes
      createdAt
      routeNumber
      staging
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateReplaceByRoute = /* GraphQL */ `
  subscription OnUpdateReplaceByRoute {
    onUpdateReplaceByRoute {
      id
      replaceByRouteStaffId
      replaceByRouteRouteId
      replaceByRouteStatusId
      replaceByRouteDeviceId
      replaceByRouteVehicleId
      replaceByRouteDailyRosterId
      replaceByRouteParkingSpaceId
      group
      notes
      createdAt
      routeNumber
      staging
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteReplaceByRoute = /* GraphQL */ `
  subscription OnDeleteReplaceByRoute {
    onDeleteReplaceByRoute {
      id
      replaceByRouteStaffId
      replaceByRouteRouteId
      replaceByRouteStatusId
      replaceByRouteDeviceId
      replaceByRouteVehicleId
      replaceByRouteDailyRosterId
      replaceByRouteParkingSpaceId
      group
      notes
      createdAt
      routeNumber
      staging
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      updatedAt
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      replaceByRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateRoute = /* GraphQL */ `
  subscription OnCreateRoute {
    onCreateRoute {
      id
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      routeVehicleId
      routeRescuerId
      routeParkingSpaceId
      routeHelperId
      routeDevice2Id
      routeStatusId
      routeHelperStatusId
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      helperUpdatedDateTime
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      helpers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      rescuer {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      helper {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      helperStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      rescuers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRoute = /* GraphQL */ `
  subscription OnUpdateRoute {
    onUpdateRoute {
      id
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      routeVehicleId
      routeRescuerId
      routeParkingSpaceId
      routeHelperId
      routeDevice2Id
      routeStatusId
      routeHelperStatusId
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      helperUpdatedDateTime
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      helpers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      rescuer {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      helper {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      helperStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      rescuers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRoute = /* GraphQL */ `
  subscription OnDeleteRoute {
    onDeleteRoute {
      id
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      routeVehicleId
      routeRescuerId
      routeParkingSpaceId
      routeHelperId
      routeDevice2Id
      routeStatusId
      routeHelperStatusId
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      helperUpdatedDateTime
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      helpers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      dailyRoster {
        id
        dailyRosterTenantId
        dailyRosterAttachmentId
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        notes {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      document {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      rescuer {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      helper {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      status {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      helperStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      rescuers {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLabelType = /* GraphQL */ `
  subscription OnCreateLabelType {
    onCreateLabelType {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          labelTypeListLabelId
          labelTypeListTypeId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateLabelType = /* GraphQL */ `
  subscription OnUpdateLabelType {
    onUpdateLabelType {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          labelTypeListLabelId
          labelTypeListTypeId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteLabelType = /* GraphQL */ `
  subscription OnDeleteLabelType {
    onDeleteLabelType {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          labelTypeListLabelId
          labelTypeListTypeId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLabelTypeList = /* GraphQL */ `
  subscription OnCreateLabelTypeList {
    onCreateLabelTypeList {
      id
      labelTypeListLabelId
      labelTypeListTypeId
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
          __typename
        }
        __typename
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
          __typename
        }
        items {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateLabelTypeList = /* GraphQL */ `
  subscription OnUpdateLabelTypeList {
    onUpdateLabelTypeList {
      id
      labelTypeListLabelId
      labelTypeListTypeId
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
          __typename
        }
        __typename
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
          __typename
        }
        items {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteLabelTypeList = /* GraphQL */ `
  subscription OnDeleteLabelTypeList {
    onDeleteLabelTypeList {
      id
      labelTypeListLabelId
      labelTypeListTypeId
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
          __typename
        }
        __typename
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
          __typename
        }
        items {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLabel = /* GraphQL */ `
  subscription OnCreateLabel {
    onCreateLabel {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          labelTypeListLabelId
          labelTypeListTypeId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      items {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateLabel = /* GraphQL */ `
  subscription OnUpdateLabel {
    onUpdateLabel {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          labelTypeListLabelId
          labelTypeListTypeId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      items {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteLabel = /* GraphQL */ `
  subscription OnDeleteLabel {
    onDeleteLabel {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          labelTypeListLabelId
          labelTypeListTypeId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      items {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLabelSubscription = /* GraphQL */ `
  subscription OnCreateLabelSubscription {
    onCreateLabelSubscription {
      id
      labelId
      staffId
      vehicleId
      group
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
          __typename
        }
        items {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateLabelSubscription = /* GraphQL */ `
  subscription OnUpdateLabelSubscription {
    onUpdateLabelSubscription {
      id
      labelId
      staffId
      vehicleId
      group
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
          __typename
        }
        items {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteLabelSubscription = /* GraphQL */ `
  subscription OnDeleteLabelSubscription {
    onDeleteLabelSubscription {
      id
      labelId
      staffId
      vehicleId
      group
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
          __typename
        }
        items {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStaff = /* GraphQL */ `
  subscription OnCreateStaff {
    onCreateStaff {
      id
      staffDefaultVehicleId
      staffDefaultVehicle2Id
      staffDefaultVehicle3Id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      drugTests {
        items {
          id
          drugTestStaffId
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      injuries {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      eocScores {
        items {
          id
          textractJobId
          eocScoreStaffId
          group
          date
          level
          average
          averageDailyCompliance
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      counselings {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentionedInNotes {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      onBoarding {
        items {
          id
          onBoardStaffId
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      physicals {
        items {
          id
          physicalStaffId
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      podQualities {
        items {
          id
          podQualityStaffId
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeHelper {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeRescuer {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagePreferencesHistory {
        items {
          id
          messagePreferencesHistoryStaffId
          group
          messagePreferenceType
          description
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cxFeedback {
        items {
          id
          staffCxFeedbackStaffId
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentor {
        items {
          id
          textractJobId
          staffMentorStaffId
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      netrdadyneAlerts {
        items {
          id
          textractJobId
          staffNetradyneAlertStaffId
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scoreCards {
        items {
          id
          staffScoreCardStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffStatusHistory {
        items {
          id
          staffStatusStaffId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      authorizedToDrive {
        items {
          id
          optionsCustomListsStaffStaffId
          optionsCustomListsStaffOptionCustomListId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      uniforms {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle2 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle3 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      routeRescuerStaff {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      properParkingSequences {
        items {
          id
          properParkingSequenceStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStaff = /* GraphQL */ `
  subscription OnUpdateStaff {
    onUpdateStaff {
      id
      staffDefaultVehicleId
      staffDefaultVehicle2Id
      staffDefaultVehicle3Id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      drugTests {
        items {
          id
          drugTestStaffId
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      injuries {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      eocScores {
        items {
          id
          textractJobId
          eocScoreStaffId
          group
          date
          level
          average
          averageDailyCompliance
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      counselings {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentionedInNotes {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      onBoarding {
        items {
          id
          onBoardStaffId
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      physicals {
        items {
          id
          physicalStaffId
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      podQualities {
        items {
          id
          podQualityStaffId
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeHelper {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeRescuer {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagePreferencesHistory {
        items {
          id
          messagePreferencesHistoryStaffId
          group
          messagePreferenceType
          description
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cxFeedback {
        items {
          id
          staffCxFeedbackStaffId
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentor {
        items {
          id
          textractJobId
          staffMentorStaffId
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      netrdadyneAlerts {
        items {
          id
          textractJobId
          staffNetradyneAlertStaffId
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scoreCards {
        items {
          id
          staffScoreCardStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffStatusHistory {
        items {
          id
          staffStatusStaffId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      authorizedToDrive {
        items {
          id
          optionsCustomListsStaffStaffId
          optionsCustomListsStaffOptionCustomListId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      uniforms {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle2 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle3 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      routeRescuerStaff {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      properParkingSequences {
        items {
          id
          properParkingSequenceStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStaff = /* GraphQL */ `
  subscription OnDeleteStaff {
    onDeleteStaff {
      id
      staffDefaultVehicleId
      staffDefaultVehicle2Id
      staffDefaultVehicle3Id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      replacedByStandbyByRoute {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      drugTests {
        items {
          id
          drugTestStaffId
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      injuries {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      eocScores {
        items {
          id
          textractJobId
          eocScoreStaffId
          group
          date
          level
          average
          averageDailyCompliance
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      counselings {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      infractions {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentionedInNotes {
        items {
          id
          staffMentionNoteId
          staffMentionStaffId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      onBoarding {
        items {
          id
          onBoardStaffId
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      physicals {
        items {
          id
          physicalStaffId
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      podQualities {
        items {
          id
          podQualityStaffId
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replaceByRoute {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeHelper {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeRescuer {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagePreferencesHistory {
        items {
          id
          messagePreferencesHistoryStaffId
          group
          messagePreferenceType
          description
          datetime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cxFeedback {
        items {
          id
          staffCxFeedbackStaffId
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      mentor {
        items {
          id
          textractJobId
          staffMentorStaffId
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      netrdadyneAlerts {
        items {
          id
          textractJobId
          staffNetradyneAlertStaffId
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scoreCards {
        items {
          id
          staffScoreCardStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      staffStatusHistory {
        items {
          id
          staffStatusStaffId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      routeStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      authorizedToDrive {
        items {
          id
          optionsCustomListsStaffStaffId
          optionsCustomListsStaffOptionCustomListId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      uniforms {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultVehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle2 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      defaultVehicle3 {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      routeRescuerStaff {
        items {
          id
          routeId
          staffId
          group
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      properParkingSequences {
        items {
          id
          properParkingSequenceStaffId
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessagePreferencesHistory = /* GraphQL */ `
  subscription OnCreateMessagePreferencesHistory {
    onCreateMessagePreferencesHistory {
      id
      messagePreferencesHistoryStaffId
      group
      messagePreferenceType
      description
      datetime
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMessagePreferencesHistory = /* GraphQL */ `
  subscription OnUpdateMessagePreferencesHistory {
    onUpdateMessagePreferencesHistory {
      id
      messagePreferencesHistoryStaffId
      group
      messagePreferenceType
      description
      datetime
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMessagePreferencesHistory = /* GraphQL */ `
  subscription OnDeleteMessagePreferencesHistory {
    onDeleteMessagePreferencesHistory {
      id
      messagePreferencesHistoryStaffId
      group
      messagePreferenceType
      description
      datetime
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStaffCxFeedback = /* GraphQL */ `
  subscription OnCreateStaffCxFeedback {
    onCreateStaffCxFeedback {
      id
      staffCxFeedbackStaffId
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStaffCxFeedback = /* GraphQL */ `
  subscription OnUpdateStaffCxFeedback {
    onUpdateStaffCxFeedback {
      id
      staffCxFeedbackStaffId
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStaffCxFeedback = /* GraphQL */ `
  subscription OnDeleteStaffCxFeedback {
    onDeleteStaffCxFeedback {
      id
      staffCxFeedbackStaffId
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStaffMentor = /* GraphQL */ `
  subscription OnCreateStaffMentor {
    onCreateStaffMentor {
      id
      textractJobId
      staffMentorStaffId
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStaffMentor = /* GraphQL */ `
  subscription OnUpdateStaffMentor {
    onUpdateStaffMentor {
      id
      textractJobId
      staffMentorStaffId
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStaffMentor = /* GraphQL */ `
  subscription OnDeleteStaffMentor {
    onDeleteStaffMentor {
      id
      textractJobId
      staffMentorStaffId
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStaffNetradyneAlert = /* GraphQL */ `
  subscription OnCreateStaffNetradyneAlert {
    onCreateStaffNetradyneAlert {
      id
      textractJobId
      staffNetradyneAlertStaffId
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStaffNetradyneAlert = /* GraphQL */ `
  subscription OnUpdateStaffNetradyneAlert {
    onUpdateStaffNetradyneAlert {
      id
      textractJobId
      staffNetradyneAlertStaffId
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStaffNetradyneAlert = /* GraphQL */ `
  subscription OnDeleteStaffNetradyneAlert {
    onDeleteStaffNetradyneAlert {
      id
      textractJobId
      staffNetradyneAlertStaffId
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStaffScoreCard = /* GraphQL */ `
  subscription OnCreateStaffScoreCard {
    onCreateStaffScoreCard {
      id
      staffScoreCardStaffId
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      psb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStaffScoreCard = /* GraphQL */ `
  subscription OnUpdateStaffScoreCard {
    onUpdateStaffScoreCard {
      id
      staffScoreCardStaffId
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      psb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStaffScoreCard = /* GraphQL */ `
  subscription OnDeleteStaffScoreCard {
    onDeleteStaffScoreCard {
      id
      staffScoreCardStaffId
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      psb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStaffStatus = /* GraphQL */ `
  subscription OnCreateStaffStatus {
    onCreateStaffStatus {
      id
      staffStatusStaffId
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStaffStatus = /* GraphQL */ `
  subscription OnUpdateStaffStatus {
    onUpdateStaffStatus {
      id
      staffStatusStaffId
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStaffStatus = /* GraphQL */ `
  subscription OnDeleteStaffStatus {
    onDeleteStaffStatus {
      id
      staffStatusStaffId
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateRouteStatus = /* GraphQL */ `
  subscription OnCreateRouteStatus {
    onCreateRouteStatus {
      id
      routeStatusRouteId
      routeStatusAssociateId
      routeStatusReplaceByRouteId
      routeStatusPreviousStatusId
      routeStatusCurrentStatusId
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      associate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      previousStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      currentStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRouteStatus = /* GraphQL */ `
  subscription OnUpdateRouteStatus {
    onUpdateRouteStatus {
      id
      routeStatusRouteId
      routeStatusAssociateId
      routeStatusReplaceByRouteId
      routeStatusPreviousStatusId
      routeStatusCurrentStatusId
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      associate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      previousStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      currentStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRouteStatus = /* GraphQL */ `
  subscription OnDeleteRouteStatus {
    onDeleteRouteStatus {
      id
      routeStatusRouteId
      routeStatusAssociateId
      routeStatusReplaceByRouteId
      routeStatusPreviousStatusId
      routeStatusCurrentStatusId
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        replaceByRouteStaffId
        replaceByRouteRouteId
        replaceByRouteStatusId
        replaceByRouteDeviceId
        replaceByRouteVehicleId
        replaceByRouteDailyRosterId
        replaceByRouteParkingSpaceId
        group
        notes
        createdAt
        routeNumber
        staging
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        updatedAt
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        replaceByRouteStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      associate {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      previousStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      currentStatus {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTenant = /* GraphQL */ `
  subscription OnCreateTenant {
    onCreateTenant {
      id
      group
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      cards {
        items {
          id
          cardTenantId
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          pickupSuccessBehaviors
          pickupSuccessBehaviorsText
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      textractjobs {
        items {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      waves {
        items {
          id
          waveTenantId
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessage {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      dailyRoster {
        items {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      invoices {
        items {
          id
          invoiceTenantId
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parentAccount {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      users {
        items {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      valueLists {
        items {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      premiumStatusHistory {
        items {
          id
          premiumStatusHistoryTenantId
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageServiceProviderHistory {
        items {
          id
          messageServiceProviderTenantId
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTenant = /* GraphQL */ `
  subscription OnUpdateTenant {
    onUpdateTenant {
      id
      group
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      cards {
        items {
          id
          cardTenantId
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          pickupSuccessBehaviors
          pickupSuccessBehaviorsText
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      textractjobs {
        items {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      waves {
        items {
          id
          waveTenantId
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessage {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      dailyRoster {
        items {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      invoices {
        items {
          id
          invoiceTenantId
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parentAccount {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      users {
        items {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      valueLists {
        items {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      premiumStatusHistory {
        items {
          id
          premiumStatusHistoryTenantId
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageServiceProviderHistory {
        items {
          id
          messageServiceProviderTenantId
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTenant = /* GraphQL */ `
  subscription OnDeleteTenant {
    onDeleteTenant {
      id
      group
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      cards {
        items {
          id
          cardTenantId
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          pickupSuccessBehaviors
          pickupSuccessBehaviorsText
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      textractjobs {
        items {
          id
          textractJobTenantId
          group
          owner
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      waves {
        items {
          id
          waveTenantId
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessage {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      dailyRoster {
        items {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      invoices {
        items {
          id
          invoiceTenantId
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parentAccount {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      users {
        items {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      valueLists {
        items {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      premiumStatusHistory {
        items {
          id
          premiumStatusHistoryTenantId
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageServiceProviderHistory {
        items {
          id
          messageServiceProviderTenantId
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCoachingHistory = /* GraphQL */ `
  subscription OnCreateCoachingHistory {
    onCreateCoachingHistory {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCoachingHistory = /* GraphQL */ `
  subscription OnUpdateCoachingHistory {
    onUpdateCoachingHistory {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCoachingHistory = /* GraphQL */ `
  subscription OnDeleteCoachingHistory {
    onDeleteCoachingHistory {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCoachingRecords = /* GraphQL */ `
  subscription OnCreateCoachingRecords {
    onCreateCoachingRecords {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCoachingRecords = /* GraphQL */ `
  subscription OnUpdateCoachingRecords {
    onUpdateCoachingRecords {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCoachingRecords = /* GraphQL */ `
  subscription OnDeleteCoachingRecords {
    onDeleteCoachingRecords {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomLists = /* GraphQL */ `
  subscription OnCreateCustomLists {
    onCreateCustomLists {
      id
      group
      type
      enabledRecordTemplates
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCustomLists = /* GraphQL */ `
  subscription OnUpdateCustomLists {
    onUpdateCustomLists {
      id
      group
      type
      enabledRecordTemplates
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCustomLists = /* GraphQL */ `
  subscription OnDeleteCustomLists {
    onDeleteCustomLists {
      id
      group
      type
      enabledRecordTemplates
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateOptionsCustomLists = /* GraphQL */ `
  subscription OnCreateOptionsCustomLists {
    onCreateOptionsCustomLists {
      id
      optionsCustomListsCustomListsId
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      recordTemplateJson
      statusType
      driverReportSetting
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counselings {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counselingTypes {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      issues {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      replaceByRoutes {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      replaceByRouteParkingSpace {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routes {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeHelperStatus {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeParkingSpace {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      previousRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      currentRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customLists {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
          __typename
        }
        __typename
      }
      associates {
        items {
          id
          optionsCustomListsStaffStaffId
          optionsCustomListsStaffOptionCustomListId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicles {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      parkingSpace {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      companies {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOptionsCustomLists = /* GraphQL */ `
  subscription OnUpdateOptionsCustomLists {
    onUpdateOptionsCustomLists {
      id
      optionsCustomListsCustomListsId
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      recordTemplateJson
      statusType
      driverReportSetting
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counselings {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counselingTypes {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      issues {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      replaceByRoutes {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      replaceByRouteParkingSpace {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routes {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeHelperStatus {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeParkingSpace {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      previousRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      currentRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customLists {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
          __typename
        }
        __typename
      }
      associates {
        items {
          id
          optionsCustomListsStaffStaffId
          optionsCustomListsStaffOptionCustomListId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicles {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      parkingSpace {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      companies {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOptionsCustomLists = /* GraphQL */ `
  subscription OnDeleteOptionsCustomLists {
    onDeleteOptionsCustomLists {
      id
      optionsCustomListsCustomListsId
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      recordTemplateJson
      statusType
      driverReportSetting
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          replacedByStandbyByRouteStatusId
          replacedByStandbyByRouteRouteId
          replacedByStandbyByRouteDailyRosterId
          replacedByStandbyByRouteStaffId
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counselings {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counselingTypes {
        items {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      issues {
        items {
          id
          infractionTypeId
          infractionStaffId
          infractionRouteId
          infractionCounselingId
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      kudos {
        items {
          id
          kudoTypeId
          kudoRouteId
          kudoStaffId
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      replaceByRoutes {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      replaceByRouteParkingSpace {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routes {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeHelperStatus {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      routeParkingSpace {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      previousRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      currentRouteStatusHistory {
        items {
          id
          routeStatusRouteId
          routeStatusAssociateId
          routeStatusReplaceByRouteId
          routeStatusPreviousStatusId
          routeStatusCurrentStatusId
          group
          associateType
          reason
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customLists {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
          __typename
        }
        __typename
      }
      associates {
        items {
          id
          optionsCustomListsStaffStaffId
          optionsCustomListsStaffOptionCustomListId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicles {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      parkingSpace {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      companies {
        items {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      __typename
    }
  }
`;
export const onCreateOptionsCustomListsStaff = /* GraphQL */ `
  subscription OnCreateOptionsCustomListsStaff {
    onCreateOptionsCustomListsStaff {
      id
      optionsCustomListsStaffStaffId
      optionsCustomListsStaffOptionCustomListId
      group
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOptionsCustomListsStaff = /* GraphQL */ `
  subscription OnUpdateOptionsCustomListsStaff {
    onUpdateOptionsCustomListsStaff {
      id
      optionsCustomListsStaffStaffId
      optionsCustomListsStaffOptionCustomListId
      group
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOptionsCustomListsStaff = /* GraphQL */ `
  subscription OnDeleteOptionsCustomListsStaff {
    onDeleteOptionsCustomListsStaff {
      id
      optionsCustomListsStaffStaffId
      optionsCustomListsStaffOptionCustomListId
      group
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      optionCustomList {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateUniform = /* GraphQL */ `
  subscription OnCreateUniform {
    onCreateUniform {
      id
      uniformSizeId
      uniformStaffId
      uniformUniformTypeId
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      uniformType {
        id
        valueListItemValueListId
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
          __typename
        }
        uniformSizes {
          nextToken
          __typename
        }
        valueList {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      size {
        id
        valueListItemValueListId
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
          __typename
        }
        uniformSizes {
          nextToken
          __typename
        }
        valueList {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUniform = /* GraphQL */ `
  subscription OnUpdateUniform {
    onUpdateUniform {
      id
      uniformSizeId
      uniformStaffId
      uniformUniformTypeId
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      uniformType {
        id
        valueListItemValueListId
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
          __typename
        }
        uniformSizes {
          nextToken
          __typename
        }
        valueList {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      size {
        id
        valueListItemValueListId
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
          __typename
        }
        uniformSizes {
          nextToken
          __typename
        }
        valueList {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUniform = /* GraphQL */ `
  subscription OnDeleteUniform {
    onDeleteUniform {
      id
      uniformSizeId
      uniformStaffId
      uniformUniformTypeId
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      uniformType {
        id
        valueListItemValueListId
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
          __typename
        }
        uniformSizes {
          nextToken
          __typename
        }
        valueList {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      size {
        id
        valueListItemValueListId
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
          __typename
        }
        uniformSizes {
          nextToken
          __typename
        }
        valueList {
          id
          valueListTenantId
          group
          key
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      userTenantId
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      zohoCrmContactRecordId
      lastLogin
      injuriesCompletedBy {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assignerTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assigneeTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessagesSent {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidentCreatedBy {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      accidentsVerified {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLogCreatedBy {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      messagesSent {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      resourcesUsage {
        items {
          id
          userInstanceId
          resourceUsageUserId
          resourceIdentifier
          group
          resourceType
          inUseAsOfDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      completedReminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      userTenantId
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      zohoCrmContactRecordId
      lastLogin
      injuriesCompletedBy {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assignerTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assigneeTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessagesSent {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidentCreatedBy {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      accidentsVerified {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLogCreatedBy {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      messagesSent {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      resourcesUsage {
        items {
          id
          userInstanceId
          resourceUsageUserId
          resourceIdentifier
          group
          resourceType
          inUseAsOfDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      completedReminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      userTenantId
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      zohoCrmContactRecordId
      lastLogin
      injuriesCompletedBy {
        items {
          id
          injuryStaffId
          injuryCompletedByUserId
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assignerTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      assigneeTasks {
        items {
          id
          taskAssigneeId
          taskAssignerId
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      recurringMessagesSent {
        items {
          id
          senderId
          recurringMessagesTenantId
          group
          messageName
          messageBody
          sendTime
          channelType
          senderName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      accidentCreatedBy {
        id
        accidentVehicleId
        accidentStaffId
        accidentVerifiedById
        accidentMaintenanceVehicleId
        accidentDamageVehicleId
        accidentOdometerReadingVehicleId
        accidentVehicleDamageRouteId
        accidentOptionCustomListId
        accidentCreatedByUserId
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageImages {
          nextToken
          scannedCount
          __typename
        }
        maintenanceDocuments {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamageRoute {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        optionCustomList {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        verifiedBy {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        maintenanceVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        damageVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        odometerReadingVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      accidentsVerified {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      counseling {
        id
        counselingUserId
        counselingStaffId
        counselingSeverityId
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        severity {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        type {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      dailyLogCreatedBy {
        id
        dailyLogVehicleId
        dailyLogTakenByUserId
        dailyLogRosteredDayId
        dailyLogCreatedByUserId
        dailyLogTakenByAssociateId
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
          __typename
        }
        rosteredDay {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        history {
          nextToken
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        creationLinkAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        takenByAssociate {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        creationLinkSentByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        takenByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      messagesSent {
        items {
          id
          senderId
          staffId
          messageRouteId
          messageTenantId
          messageAttachmentId
          messageReplaceByRouteId
          carrierMessageId
          group
          createdAt
          deviceId
          messageType
          channelType
          destinationNumber
          deviceDestinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          deviceSmsStatus
          smsSendInformation
          deviceSmsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          owner
          destinationName
          hasParts
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      resourcesUsage {
        items {
          id
          userInstanceId
          resourceUsageUserId
          resourceIdentifier
          group
          resourceType
          inUseAsOfDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      completedReminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messageReadStatus {
        items {
          id
          userID
          staffID
          deviceID
          group
          readUpToDateTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateValueList = /* GraphQL */ `
  subscription OnCreateValueList {
    onCreateValueList {
      id
      valueListTenantId
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      items {
        items {
          id
          valueListItemValueListId
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateValueList = /* GraphQL */ `
  subscription OnUpdateValueList {
    onUpdateValueList {
      id
      valueListTenantId
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      items {
        items {
          id
          valueListItemValueListId
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteValueList = /* GraphQL */ `
  subscription OnDeleteValueList {
    onDeleteValueList {
      id
      valueListTenantId
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      items {
        items {
          id
          valueListItemValueListId
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateValueListItem = /* GraphQL */ `
  subscription OnCreateValueListItem {
    onCreateValueListItem {
      id
      valueListItemValueListId
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      uniformSizes {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      valueList {
        id
        valueListTenantId
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        items {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateValueListItem = /* GraphQL */ `
  subscription OnUpdateValueListItem {
    onUpdateValueListItem {
      id
      valueListItemValueListId
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      uniformSizes {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      valueList {
        id
        valueListTenantId
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        items {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteValueListItem = /* GraphQL */ `
  subscription OnDeleteValueListItem {
    onDeleteValueListItem {
      id
      valueListItemValueListId
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      uniformSizes {
        items {
          id
          uniformSizeId
          uniformStaffId
          uniformUniformTypeId
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      valueList {
        id
        valueListTenantId
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        items {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateVehicle = /* GraphQL */ `
  subscription OnCreateVehicle {
    onCreateVehicle {
      id
      vehicleDeviceId
      vehicleDevice2Id
      vehicleCompanyId
      vehicleParkingSpaceId
      vehicleVehicleTypeId
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceRecords {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      odometerReadings {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      mentionedInNotes {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replacedRoutes {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultStaff {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff2 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff3 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleType {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      company {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicleRecordHistory {
        items {
          id
          vehicleId
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      maintenance {
        items {
          id
          vehicleMaintenanceVehicleId
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      vehicleStatusHistory {
        items {
          id
          vehicleStatusVehicleId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateVehicle = /* GraphQL */ `
  subscription OnUpdateVehicle {
    onUpdateVehicle {
      id
      vehicleDeviceId
      vehicleDevice2Id
      vehicleCompanyId
      vehicleParkingSpaceId
      vehicleVehicleTypeId
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceRecords {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      odometerReadings {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      mentionedInNotes {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replacedRoutes {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultStaff {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff2 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff3 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleType {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      company {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicleRecordHistory {
        items {
          id
          vehicleId
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      maintenance {
        items {
          id
          vehicleMaintenanceVehicleId
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      vehicleStatusHistory {
        items {
          id
          vehicleStatusVehicleId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteVehicle = /* GraphQL */ `
  subscription OnDeleteVehicle {
    onDeleteVehicle {
      id
      vehicleDeviceId
      vehicleDevice2Id
      vehicleCompanyId
      vehicleParkingSpaceId
      vehicleVehicleTypeId
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      maintenanceRecords {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleDamage {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      odometerReadings {
        items {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        deviceLastMessageTimestamp
        deviceLastMessage
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        route2 {
          nextToken
          scannedCount
          __typename
        }
        vehicle {
          nextToken
          scannedCount
          __typename
        }
        vehicle2 {
          nextToken
          scannedCount
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      dailyLogs {
        items {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      documents {
        items {
          id
          documentStaffId
          documentRouteId
          documentVehicleId
          documentIncidentId
          documentInfractionId
          documentImageCounselingId
          documentImagevehicleDamageId
          documentOptionCustomListsId
          documentMaintenanceId
          documentReplaceByRouteId
          documentInjuryId
          documentDailyLogId
          documentTakenByUserId
          documentTakenByAssociateId
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      mentionedInNotes {
        items {
          id
          vehicleMentionNoteId
          vehicleMentionVehicleId
          group
          date
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      replacedRoutes {
        items {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      route {
        items {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      labels {
        items {
          id
          labelId
          staffId
          vehicleId
          group
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      defaultStaff {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff2 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      defaultStaff3 {
        items {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        __typename
      }
      vehicleType {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      company {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      parkingSpace {
        id
        optionsCustomListsCustomListsId
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        counselingTypes {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        issues {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        replaceByRoutes {
          nextToken
          scannedCount
          __typename
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        routes {
          nextToken
          scannedCount
          __typename
        }
        routeHelperStatus {
          nextToken
          scannedCount
          __typename
        }
        routeParkingSpace {
          nextToken
          scannedCount
          __typename
        }
        previousRouteStatusHistory {
          nextToken
          __typename
        }
        currentRouteStatusHistory {
          nextToken
          __typename
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
          __typename
        }
        associates {
          nextToken
          scannedCount
          __typename
        }
        vehicles {
          nextToken
          scannedCount
          __typename
        }
        parkingSpace {
          nextToken
          scannedCount
          __typename
        }
        companies {
          nextToken
          scannedCount
          __typename
        }
        __typename
      }
      vehicleRecordHistory {
        items {
          id
          vehicleId
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      maintenance {
        items {
          id
          vehicleMaintenanceVehicleId
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reminders {
        items {
          id
          vehicleMaintenanceReminderVehicleId
          vehicleMaintenanceReminderCompletedById
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      vehicleStatusHistory {
        items {
          id
          vehicleStatusVehicleId
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateVehicleHistory = /* GraphQL */ `
  subscription OnCreateVehicleHistory {
    onCreateVehicleHistory {
      id
      vehicleId
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      createdAt
      updatedAt
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateVehicleHistory = /* GraphQL */ `
  subscription OnUpdateVehicleHistory {
    onUpdateVehicleHistory {
      id
      vehicleId
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      createdAt
      updatedAt
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteVehicleHistory = /* GraphQL */ `
  subscription OnDeleteVehicleHistory {
    onDeleteVehicleHistory {
      id
      vehicleId
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      createdAt
      updatedAt
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateVehicleMaintenance = /* GraphQL */ `
  subscription OnCreateVehicleMaintenance {
    onCreateVehicleMaintenance {
      id
      vehicleMaintenanceVehicleId
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateVehicleMaintenance = /* GraphQL */ `
  subscription OnUpdateVehicleMaintenance {
    onUpdateVehicleMaintenance {
      id
      vehicleMaintenanceVehicleId
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteVehicleMaintenance = /* GraphQL */ `
  subscription OnDeleteVehicleMaintenance {
    onDeleteVehicleMaintenance {
      id
      vehicleMaintenanceVehicleId
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateVehicleMaintenanceReminder = /* GraphQL */ `
  subscription OnCreateVehicleMaintenanceReminder {
    onCreateVehicleMaintenanceReminder {
      id
      vehicleMaintenanceReminderVehicleId
      vehicleMaintenanceReminderCompletedById
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateVehicleMaintenanceReminder = /* GraphQL */ `
  subscription OnUpdateVehicleMaintenanceReminder {
    onUpdateVehicleMaintenanceReminder {
      id
      vehicleMaintenanceReminderVehicleId
      vehicleMaintenanceReminderCompletedById
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteVehicleMaintenanceReminder = /* GraphQL */ `
  subscription OnDeleteVehicleMaintenanceReminder {
    onDeleteVehicleMaintenanceReminder {
      id
      vehicleMaintenanceReminderVehicleId
      vehicleMaintenanceReminderCompletedById
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateVehicleStatus = /* GraphQL */ `
  subscription OnCreateVehicleStatus {
    onCreateVehicleStatus {
      id
      vehicleStatusVehicleId
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateVehicleStatus = /* GraphQL */ `
  subscription OnUpdateVehicleStatus {
    onUpdateVehicleStatus {
      id
      vehicleStatusVehicleId
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteVehicleStatus = /* GraphQL */ `
  subscription OnDeleteVehicleStatus {
    onDeleteVehicleStatus {
      id
      vehicleStatusVehicleId
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        vehicleDeviceId
        vehicleDevice2Id
        vehicleCompanyId
        vehicleParkingSpaceId
        vehicleVehicleTypeId
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        maintenanceRecords {
          nextToken
          scannedCount
          __typename
        }
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        odometerReadings {
          nextToken
          scannedCount
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        dailyLogs {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        replacedRoutes {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        defaultStaff {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff2 {
          nextToken
          scannedCount
          __typename
        }
        defaultStaff3 {
          nextToken
          scannedCount
          __typename
        }
        vehicleType {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicleRecordHistory {
          nextToken
          __typename
        }
        maintenance {
          nextToken
          __typename
        }
        reminders {
          nextToken
          __typename
        }
        vehicleStatusHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateRouteStaffRescuer = /* GraphQL */ `
  subscription OnCreateRouteStaffRescuer {
    onCreateRouteStaffRescuer {
      id
      routeId
      staffId
      group
      startTime
      endTime
      totalRescuedPackages
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRouteStaffRescuer = /* GraphQL */ `
  subscription OnUpdateRouteStaffRescuer {
    onUpdateRouteStaffRescuer {
      id
      routeId
      staffId
      group
      startTime
      endTime
      totalRescuedPackages
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRouteStaffRescuer = /* GraphQL */ `
  subscription OnDeleteRouteStaffRescuer {
    onDeleteRouteStaffRescuer {
      id
      routeId
      staffId
      group
      startTime
      endTime
      totalRescuedPackages
      createdAt
      updatedAt
      route {
        id
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        routeVehicleId
        routeRescuerId
        routeParkingSpaceId
        routeHelperId
        routeDevice2Id
        routeStatusId
        routeHelperStatusId
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        helperUpdatedDateTime
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        helpers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
          __typename
        }
        dailyRoster {
          id
          dailyRosterTenantId
          dailyRosterAttachmentId
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        document {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        rescuer {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        helper {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        parkingSpace {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        helperStatus {
          id
          optionsCustomListsCustomListsId
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
          __typename
        }
        vehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        rescuers {
          nextToken
          __typename
        }
        __typename
      }
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessageReader = /* GraphQL */ `
  subscription OnCreateMessageReader {
    onCreateMessageReader {
      id
      userID
      messageID
      group
      createdAt
      updatedAt
      message {
        id
        senderId
        staffId
        messageRouteId
        messageTenantId
        messageAttachmentId
        messageReplaceByRouteId
        carrierMessageId
        group
        createdAt
        deviceId
        messageType
        channelType
        destinationNumber
        deviceDestinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        deviceSmsStatus
        smsSendInformation
        deviceSmsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        owner
        destinationName
        hasParts
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        replaceByRoute {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        sender {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        isReadBy {
          nextToken
          __typename
        }
        __typename
      }
      readBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMessageReader = /* GraphQL */ `
  subscription OnUpdateMessageReader {
    onUpdateMessageReader {
      id
      userID
      messageID
      group
      createdAt
      updatedAt
      message {
        id
        senderId
        staffId
        messageRouteId
        messageTenantId
        messageAttachmentId
        messageReplaceByRouteId
        carrierMessageId
        group
        createdAt
        deviceId
        messageType
        channelType
        destinationNumber
        deviceDestinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        deviceSmsStatus
        smsSendInformation
        deviceSmsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        owner
        destinationName
        hasParts
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        replaceByRoute {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        sender {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        isReadBy {
          nextToken
          __typename
        }
        __typename
      }
      readBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMessageReader = /* GraphQL */ `
  subscription OnDeleteMessageReader {
    onDeleteMessageReader {
      id
      userID
      messageID
      group
      createdAt
      updatedAt
      message {
        id
        senderId
        staffId
        messageRouteId
        messageTenantId
        messageAttachmentId
        messageReplaceByRouteId
        carrierMessageId
        group
        createdAt
        deviceId
        messageType
        channelType
        destinationNumber
        deviceDestinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        deviceSmsStatus
        smsSendInformation
        deviceSmsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        owner
        destinationName
        hasParts
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
          __typename
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          deviceLastMessageTimestamp
          deviceLastMessage
          createdAt
          updatedAt
          __typename
        }
        replaceByRoute {
          id
          replaceByRouteStaffId
          replaceByRouteRouteId
          replaceByRouteStatusId
          replaceByRouteDeviceId
          replaceByRouteVehicleId
          replaceByRouteDailyRosterId
          replaceByRouteParkingSpaceId
          group
          notes
          createdAt
          routeNumber
          staging
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          updatedAt
          __typename
        }
        route {
          id
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          routeVehicleId
          routeRescuerId
          routeParkingSpaceId
          routeHelperId
          routeDevice2Id
          routeStatusId
          routeHelperStatusId
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          helperUpdatedDateTime
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
          __typename
        }
        staff {
          id
          staffDefaultVehicleId
          staffDefaultVehicle2Id
          staffDefaultVehicle3Id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        sender {
          id
          userTenantId
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        isReadBy {
          nextToken
          __typename
        }
        __typename
      }
      readBy {
        id
        userTenantId
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        zohoCrmContactRecordId
        lastLogin
        injuriesCompletedBy {
          nextToken
          __typename
        }
        assignerTasks {
          nextToken
          __typename
        }
        assigneeTasks {
          nextToken
          __typename
        }
        recurringMessagesSent {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidentCreatedBy {
          id
          accidentVehicleId
          accidentStaffId
          accidentVerifiedById
          accidentMaintenanceVehicleId
          accidentDamageVehicleId
          accidentOdometerReadingVehicleId
          accidentVehicleDamageRouteId
          accidentOptionCustomListId
          accidentCreatedByUserId
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
          __typename
        }
        accidentsVerified {
          nextToken
          scannedCount
          __typename
        }
        counseling {
          id
          counselingUserId
          counselingStaffId
          counselingSeverityId
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
          __typename
        }
        dailyLogCreatedBy {
          id
          dailyLogVehicleId
          dailyLogTakenByUserId
          dailyLogRosteredDayId
          dailyLogCreatedByUserId
          dailyLogTakenByAssociateId
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          specificPhotos
          createdAt
          updatedAt
          __typename
        }
        messagesSent {
          nextToken
          __typename
        }
        resourcesUsage {
          nextToken
          __typename
        }
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        completedReminders {
          nextToken
          __typename
        }
        messagesRead {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessageReadStatus = /* GraphQL */ `
  subscription OnCreateMessageReadStatus {
    onCreateMessageReadStatus {
      id
      userID
      staffID
      deviceID
      group
      readUpToDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessageReadStatus = /* GraphQL */ `
  subscription OnUpdateMessageReadStatus {
    onUpdateMessageReadStatus {
      id
      userID
      staffID
      deviceID
      group
      readUpToDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessageReadStatus = /* GraphQL */ `
  subscription OnDeleteMessageReadStatus {
    onDeleteMessageReadStatus {
      id
      userID
      staffID
      deviceID
      group
      readUpToDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePremiumStatusHistory = /* GraphQL */ `
  subscription OnCreatePremiumStatusHistory {
    onCreatePremiumStatusHistory {
      id
      premiumStatusHistoryTenantId
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePremiumStatusHistory = /* GraphQL */ `
  subscription OnUpdatePremiumStatusHistory {
    onUpdatePremiumStatusHistory {
      id
      premiumStatusHistoryTenantId
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePremiumStatusHistory = /* GraphQL */ `
  subscription OnDeletePremiumStatusHistory {
    onDeletePremiumStatusHistory {
      id
      premiumStatusHistoryTenantId
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessageServiceProvider = /* GraphQL */ `
  subscription OnCreateMessageServiceProvider {
    onCreateMessageServiceProvider {
      id
      messageServiceProviderTenantId
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMessageServiceProvider = /* GraphQL */ `
  subscription OnUpdateMessageServiceProvider {
    onUpdateMessageServiceProvider {
      id
      messageServiceProviderTenantId
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMessageServiceProvider = /* GraphQL */ `
  subscription OnDeleteMessageServiceProvider {
    onDeleteMessageServiceProvider {
      id
      messageServiceProviderTenantId
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        group
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
          __typename
        }
        companyScoreCardsByYearWeek {
          nextToken
          __typename
        }
        textractjobs {
          nextToken
          __typename
        }
        waves {
          nextToken
          __typename
        }
        recurringMessage {
          nextToken
          __typename
        }
        updatedAt
        dailyRoster {
          nextToken
          __typename
        }
        invoices {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        parentAccount {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        users {
          nextToken
          __typename
        }
        valueLists {
          nextToken
          __typename
        }
        premiumStatusHistory {
          nextToken
          __typename
        }
        messageServiceProviderHistory {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTelnyx = /* GraphQL */ `
  subscription OnCreateTelnyx {
    onCreateTelnyx {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTelnyx = /* GraphQL */ `
  subscription OnUpdateTelnyx {
    onUpdateTelnyx {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTelnyx = /* GraphQL */ `
  subscription OnDeleteTelnyx {
    onDeleteTelnyx {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAuditLog = /* GraphQL */ `
  subscription OnCreateAuditLog {
    onCreateAuditLog {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAuditLog = /* GraphQL */ `
  subscription OnUpdateAuditLog {
    onUpdateAuditLog {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAuditLog = /* GraphQL */ `
  subscription OnDeleteAuditLog {
    onDeleteAuditLog {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
      __typename
    }
  }
`;
export const onCreateProperParkingSequenceSummary = /* GraphQL */ `
  subscription OnCreateProperParkingSequenceSummary {
    onCreateProperParkingSequenceSummary {
      id
      properParkingSequenceSummaryTextractJobId
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        textractJobTenantId
        group
        owner
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          podQualitySummaryTextractJobId
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        cxFeedbackSummary {
          id
          cxFeedbackSummaryTextractJobId
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        properParkingSequenceSummary {
          id
          properParkingSequenceSummaryTextractJobId
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProperParkingSequenceSummary = /* GraphQL */ `
  subscription OnUpdateProperParkingSequenceSummary {
    onUpdateProperParkingSequenceSummary {
      id
      properParkingSequenceSummaryTextractJobId
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        textractJobTenantId
        group
        owner
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          podQualitySummaryTextractJobId
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        cxFeedbackSummary {
          id
          cxFeedbackSummaryTextractJobId
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        properParkingSequenceSummary {
          id
          properParkingSequenceSummaryTextractJobId
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProperParkingSequenceSummary = /* GraphQL */ `
  subscription OnDeleteProperParkingSequenceSummary {
    onDeleteProperParkingSequenceSummary {
      id
      properParkingSequenceSummaryTextractJobId
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        textractJobTenantId
        group
        owner
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          podQualitySummaryTextractJobId
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
          __typename
        }
        cxFeedbackSummary {
          id
          cxFeedbackSummaryTextractJobId
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        tenant {
          id
          group
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
          __typename
        }
        properParkingSequenceSummary {
          id
          properParkingSequenceSummaryTextractJobId
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProperParkingSequence = /* GraphQL */ `
  subscription OnCreateProperParkingSequence {
    onCreateProperParkingSequence {
      id
      properParkingSequenceStaffId
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateProperParkingSequence = /* GraphQL */ `
  subscription OnUpdateProperParkingSequence {
    onUpdateProperParkingSequence {
      id
      properParkingSequenceStaffId
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteProperParkingSequence = /* GraphQL */ `
  subscription OnDeleteProperParkingSequence {
    onDeleteProperParkingSequence {
      id
      properParkingSequenceStaffId
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        staffDefaultVehicleId
        staffDefaultVehicle2Id
        staffDefaultVehicle3Id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        replacedByStandbyByRoute {
          nextToken
          __typename
        }
        drugTests {
          nextToken
          __typename
        }
        injuries {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
          __typename
        }
        eocScores {
          nextToken
          __typename
        }
        counselings {
          nextToken
          scannedCount
          __typename
        }
        documents {
          nextToken
          scannedCount
          __typename
        }
        infractions {
          nextToken
          scannedCount
          __typename
        }
        kudos {
          nextToken
          scannedCount
          __typename
        }
        messages {
          nextToken
          __typename
        }
        mentionedInNotes {
          nextToken
          __typename
        }
        onBoarding {
          nextToken
          __typename
        }
        physicals {
          nextToken
          __typename
        }
        podQualities {
          nextToken
          __typename
        }
        replaceByRoute {
          nextToken
          scannedCount
          __typename
        }
        route {
          nextToken
          scannedCount
          __typename
        }
        routeHelper {
          nextToken
          scannedCount
          __typename
        }
        routeRescuer {
          nextToken
          scannedCount
          __typename
        }
        labels {
          nextToken
          __typename
        }
        messagePreferencesHistory {
          nextToken
          __typename
        }
        cxFeedback {
          nextToken
          __typename
        }
        mentor {
          nextToken
          __typename
        }
        netrdadyneAlerts {
          nextToken
          __typename
        }
        scoreCards {
          nextToken
          __typename
        }
        staffStatusHistory {
          nextToken
          __typename
        }
        routeStatusHistory {
          nextToken
          __typename
        }
        authorizedToDrive {
          nextToken
          scannedCount
          __typename
        }
        uniforms {
          nextToken
          __typename
        }
        defaultVehicle {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle2 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        defaultVehicle3 {
          id
          vehicleDeviceId
          vehicleDevice2Id
          vehicleCompanyId
          vehicleParkingSpaceId
          vehicleVehicleTypeId
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
          __typename
        }
        routeRescuerStaff {
          nextToken
          __typename
        }
        messageReadStatus {
          nextToken
          __typename
        }
        properParkingSequences {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
// import checkJobsStatus from './checkJobsStatus'
import { API, graphqlOperation } from 'aws-amplify';
import {updateMessage} from '@/api/mutations'
import { nanoid } from 'nanoid'
import { labelTypeByNameAndGroup } from "@/mixins/labelsQueries";
import { mixinMethods } from "@/main";
import { getAccident, listSystems } from '@/graphql/queries';
import { updateSystem } from "@/graphql/mutations";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: '4.0.1',
    sessionInfo: null,
    userInfo: null,
    lastUserSession: null,
    companyLogo: null,
    signUpCredentials: null,
    valuelists: {},
    vehicleTab: "Dashboard",
    daTab: "Dashboard",
    performanceCoachingTab: "Performance Tracking",
    vehicleCount: null,
    states: ["Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "District Of Columbia", "Federated States Of Micronesia", "Florida", "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Marshall Islands", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Northern Mariana Islands", "Ohio", "Oklahoma", "Oregon", "Palau", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virgin Islands", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"],
    stateAbbreviations: {
      "AL": "Alabama", "AK": "Alaska", "AS": "American Samoa", "AZ": "Arizona", 
      "AR": "Arkansas", "CA": "California", "CO": "Colorado", "CT": "Connecticut", 
      "DE": "Delaware", "DC": "District Of Columbia", "FM": "Federated States Of Micronesia", 
      "FL": "Florida", "GA": "Georgia", "GU": "Guam", "HI": "Hawaii", "ID": "Idaho", 
      "IL": "Illinois", "IN": "Indiana", "IA": "Iowa", "KS": "Kansas", "KY": "Kentucky", 
      "LA": "Louisiana", "ME": "Maine", "MH": "Marshall Islands", "MD": "Maryland", 
      "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", 
      "MO": "Missouri", "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", 
      "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico", "NY": "New York", 
      "NC": "North Carolina", "ND": "North Dakota", "MP": "Northern Mariana Islands", 
      "OH": "Ohio", "OK": "Oklahoma", "OR": "Oregon", "PW": "Palau", "PA": "Pennsylvania", 
      "PR": "Puerto Rico", "RI": "Rhode Island", "SC": "South Carolina", 
      "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", 
      "VT": "Vermont", "VI": "Virgin Islands", "VA": "Virginia", "WA": "Washington", 
      "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming"
    },
    jobs: [],
    monitoringJobs: false,
    notifications: [],
    messengerNotifications: 0,
    settingsView: 'SettingsNoSelection',
    messengerStaff: [],
    staffList: [],
    vehicleList: [],
    AssociateListFiltersForRDS: [],
    VehicleListFiltersForRDS:[],
    VehicleListFilters: [],
    messengerUnreadToggle: 'All Messages',
    netradyneReportDateRange: [],
    reminderMileageDueInSortOrder: null,
    pendingImportJobs: [],
    importId: '',
    tempStatusCheckedDAlist: ['Active'],
    tempStatusCheckedVehiclelist: ['Active'],
    tempStatusCheckedReports:{},
    supportAdministrator: false,
    environment: process.env.VUE_APP_HERA_ENV,
    isDevEnvironment: process.env.VUE_APP_HERA_ENV === 'development',
    isProdEnvironment: process.env.VUE_APP_HERA_ENV === 'production',
    labelTypes: [],
    labelFiltersList: [],
    statusFiltersList: [],
    hasUserPermissions: process.env.VUE_APP_USER_PERMISSIONS === "1",
    filtersAssociateStatus: ['Active'],
    filtersCounselingStatus:[],
    filtersCounselingSeverity: [],
    filtersCounselingType: [],
    dateOfOccurrenceCounseling: '',
    dateSendCounseling: '',
    filterStatusVehicleMaintenance: [],
    filterMaintStatusVehicleMaintenance: [],
    filterDateVehicleMaintenance: '',
    filterTypeOfActivityDailyReport: [],
    filterRosterStatusDailyReport: [],
    isGeneratingCounselingFiles: false,
    readOnlyAccess: {},
    customList: [],
    authorizedToDriveValidationTemporarilyDisabled: false,
    customListOption: {
      default: false,
      isEnabled: true,
      isCustom: true,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true,
      recordTemplateJson: null,
      accidents: { items: [] },
      associates: { items: [] },
      vehicles: { items: [] },
      parkingSpace: { items: [] },
      replaceByRouteParkingSpace: { items: [] },
      routeParkingSpace: { items: [] },
      counselings: { items: [] },
      issues: { items: [] },
      kudos: { items: [] },
      routes: { items: [] },
      counselingsByType: { items: [] },
      tempAdded: true
    },
    tenantCoachingInfo: {},
    daPerformanceData: [],
    daScore: {},
    updatingDailyRosterRoute: false,
    systemList: [],
    vehicleStatisticsFilters: {
      status: [],
      vehicleType: [],
      labels: []
    },
    filtersVehicleTypeReport: {
      status: [],
      vehicleType: [],
    },
    filtersVehicleList: {
      status: [],
      vehicleType: [],
      labels: [],
      company: [],
    },
    filterAssociateList: {
      status: [],
      labels: [],
      prefferedDaysOff: []
    },
    filtersSearchType: [],
    messengerData: [],
    performanceImportsNotification: [], // { id:string, notificationInstance: function }
    containerData:[], //defaultLayout expand/collapse button
    messengerTabs: {
      associate: 'Active',
      message: 'All'
    },
    notesInUse: {
      standUpNote: false,
      generalNote: false,
      fleetNote: false
    }
  },
  getters: {
    isLoggedIn: state => {
      return !!state.userInfo?.id
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    },
    groups: state => {
      var groups
      if(state.sessionInfo){
        groups = state.sessionInfo.signInUserSession.accessToken.payload['cognito:groups']
      }
      else{
        groups = undefined
      }
      return groups
    },

    // account status
    isStandardAccount: (state, getters) => {
      return state?.userInfo?.tenant.accountPremiumStatus 
      && (
        state.userInfo.tenant.accountPremiumStatus.includes("standard")
        && !getters.isPlanNone
      )
    },
    isBundleAccount: (state, getters) => {
      return state?.userInfo?.tenant.accountPremiumStatus 
      && (
        state.userInfo.tenant.accountPremiumStatus.includes("bundle")
        && !getters.isPlanNone
      )
    },
    isTrialAccount: (state, getters) => {
      return state?.userInfo?.tenant.accountPremiumStatus 
      && (
        state.userInfo.tenant.accountPremiumStatus.includes("trial")
        && !getters.isPlanNone
      )
    },
    isUnpaidAccount: (state, getters) => {
      return state?.userInfo?.tenant.accountPremiumStatus && state.userInfo.tenant.accountPremiumStatus.includes("unpaid")
    },
    isTrialExpired: (state, getters) => {
      return  new Date(state?.userInfo?.tenant.trialExpDate).getTime() <  new Date().getTime()
    },
    isOustandingExpired: (state, getters) => {
      return getters.isUnpaidAccount && ( new Date(state.userInfo.tenant.payOutstandingByDate).getTime() <  new Date().getTime())
    },
    isPlanNone: (state, getters) => {
      return state?.userInfo?.tenant.accountPremiumStatus && state.userInfo.tenant.accountPremiumStatus.includes("None")
    },

    //PREMIUM TENANT PLANS/TENANT PERMISSIONS
    hasPerformanceCoaching: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return (state?.userInfo?.tenant.accountPremiumStatus && state?.userInfo?.tenant.accountPremiumStatus.includes("performance") || (!getters.isTrialExpired) || getters.isBundleAccount)
      && !getters.isPlanNone
    },
    hasDailyRostering: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return (state?.userInfo?.tenant.accountPremiumStatus && state?.userInfo?.tenant.accountPremiumStatus.includes("rostering") || (!getters.isTrialExpired) || getters.isBundleAccount)
      && !getters.isPlanNone
    },
    hasStaffManagement: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return (state?.userInfo?.tenant.accountPremiumStatus && state?.userInfo?.tenant.accountPremiumStatus.includes("staff") || (!getters.isTrialExpired) || getters.isBundleAccount)
      && !getters.isPlanNone
    },
    hasVehicleManagement: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return (state?.userInfo?.tenant.accountPremiumStatus && state?.userInfo?.tenant.accountPremiumStatus.includes("vehicles") || (!getters.isTrialExpired) || getters.isBundleAccount)
      && !getters.isPlanNone
    },
    hasReadOnlyAccess: (state, getters) => (permission) => {
      if(state.readOnlyAccess.hasOwnProperty(permission) && !getters.isPlanNone) {
        return state.readOnlyAccess[permission]
      }
      return false
    },

    //USER PERMISSIONS
    hasIncidentAccess: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      const tenant = state?.userInfo?.tenant;
      const accountPremiumStatus = state?.userInfo?.tenant.accountPremiumStatus;

      return (
        accountPremiumStatus.includes('bundle') ||
        accountPremiumStatus.includes('staff') ||
        accountPremiumStatus.includes('vehicles') ||
        (accountPremiumStatus.includes('trial') && !tenant.trialExpDate) ||
        (accountPremiumStatus.includes('trial') && !getters.isTrialExpired)
      );
    },
    hasCounselingManagement: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return (
        !state?.hasUserPermissions || (state?.hasUserPermissions && (state?.userInfo?.permissionFullAccess || state?.userInfo?.permissionManageCounselings)) 
        || getters?.isAdmin
      );
    },
    hasCounselingSettings: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return !state?.hasUserPermissions || (state?.hasUserPermissions && state?.userInfo?.permissionCounselings);
    },
    hasMessengerManagement: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      if (getters.hasFullAccess && !getters.isPlanNone) return true;
      return state?.userInfo?.permissionMessenger ?? true
    },
    hasDailyRosteringManagement: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return state?.userInfo?.permissionDailyRostering ?? true
    },
    hasPerformanceCoachingManagement: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return state?.userInfo?.permissionPerformanceCoaching ?? true
    },
    hasDAManagement: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return state?.userInfo?.permissionDAManagement ?? true
      
    },
    hasMessageTemplateManagement: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      if (getters.hasFullAccess) return true;
      return state?.userInfo?.permissionMessageTemplate ?? true      
    },
    hasVehicleManagementPermission: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return state?.userInfo?.permissionVehicleManagement ?? true      
    },
    hasTasksReports: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      return state?.userInfo?.permissionTasksReports ?? true      
    },
    hasVehiclePhotoLogs: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      if (getters.hasFullAccess) return true;
      return state?.userInfo?.permissionVehiclePhotoLogs ?? true      
    },
    hasCustomListsManagement: (state, getters) => {
      if (getters.hasLogguedSupportAdministrator) return true;
      if (getters.hasFullAccess) return true;
      return (
        !state?.hasUserPermissions ||
        (state?.hasUserPermissions && (state?.userInfo?.permissionFullAccess || state?.userInfo?.permissionCustomLists))
      );
    },
    // hasMessaging: (state, getters) => {
    //   return state.userInfo.tenant.accountPremiumStatus.includes("messaging") || (getters.isTrialAccount && !getters.isTrialExpired) || getters.isBundleAccount
    // },

    // user roles
    isSystemAdmin: (state, getters) => {
      return getters.groups && getters.groups.includes("system_admin")
    },
    isTeamLead: (state, getters) => {
      return state?.userInfo?.role.includes("teamlead")
    },
    isAdmin: (state, getters) => {
      if(!getters.groups) return false
      let isSystemAdmin = getters.groups && getters.groups.includes("system_admin")
      let isAdmin = getters.groups && getters.groups.includes("admin") || state?.userInfo?.role == "admin"
      return isSystemAdmin || isAdmin
    },
    hasLogguedSupportAdministrator: (state) => {
      return state.supportAdministrator
    },
    hasFullAccess: (state) => {
      return state?.userInfo?.permissionFullAccess
    },
    currEnv: (state) => {
      return state.environment
    },
    isDevEnv: (state) => {
      return state.isDevEnvironment
    },
    isProdEnv: (state) => {
      return state.isProdEnvironment
    },
    getCustomList: (state) => {
      return state.customList
    },
    vehicleTypeList: (state) => {
      const vehicleTypes = state.customList.find(item => item.type === 'vehicle-type')
      return vehicleTypes ? vehicleTypes.options : []
    },
    vehicleTypeListObject: (state, getters) => {
      const options = getters.vehicleTypeList
      const object = {}
      options.forEach(item => {
        if(!object[item.option]) object[item.option] = item
      })
      return object
    },
    vehicleTypeListObjectLowerCase: (state, getters) => {
      return Object.keys(getters.vehicleTypeListObject).reduce((acc, key) => {
        acc[key.toLowerCase()] = getters.vehicleTypeListObject[key];
        return acc;
      }, {});
    },
    parkingSpaceList: (state) => {
      const parkingSpaces = state.customList.find(item => item.type === 'parking-space')
      return parkingSpaces ? parkingSpaces.options : []
    },
    incidentTypeList: (state) => {
      let incidentTypes = state.customList.find(item => item.type === 'incident-type')
      return incidentTypes ? incidentTypes.options.sort((a, b) => a.order - b.order) : []
    },
    counselingSeverityTypeList: (state) => {
      const counselingSeverityTypes = state.customList.find(item => item.type === 'counseling-severity')
      return counselingSeverityTypes ? counselingSeverityTypes.options.sort((a, b) => a.order - b.order) : []
    },
    counselingTypeList: (state) => {
      const counselingTypes = state.customList.find(item => item.type === 'counseling-type')
      return counselingTypes ? counselingTypes.options.sort((a, b) => a.order - b.order) : []
    },
    issueTypeList: (state) => {
      const issueTypes = state.customList.find(item => item.type === 'issue-type')
      return issueTypes ? issueTypes.options : []
    },
    kudoTypeList: (state) => {
      const kudoTypes = state.customList.find(item => item.type === 'kudo-type')
      return kudoTypes ? kudoTypes.options : []
    },
    issueTypeObject: (state, getters) => {
      const options = getters.issueTypeList
      const object = {}
      options.forEach(item => {
        if(!object[item.option]) object[item.option] = item
      })
      return object
    },
    kudoTypeObject: (state, getters) => {
      const options = getters.kudoTypeList
      const object = {}
      options.forEach(item => {
        if(!object[item.option]) object[item.option] = item
      })
      return object
    },
    counselingTypeList: (state) => {
      const counselingTypes = state.customList.find(item => item.type === 'counseling-type')
      return counselingTypes ? counselingTypes.options : []
    },
    vehicleCompanyList: (state) => {
      const vehicleCompanies = state.customList.find(item => item.type === 'vehicle-company')
      return vehicleCompanies ? vehicleCompanies.options : []
    },
    rosterStatusList: (state) => {
      const rosterStatus = state.customList.find(item => item.type === 'roster-status')
      return rosterStatus ? rosterStatus.options : []
    },
    photoLogList: (state) => {
      const rosterStatus = state.customList.find(item => item.type === 'photo-log')
      return rosterStatus ? rosterStatus.options : []
    },
    getCustomListByType: (state) => (type) => {
      const customList = state.customList.find(item => item.type == type)
      return customList ? customList : {}
    },
    photoLogOptionsCustomList: (state) => {
      const photoLogCustomList = state.customList.find(item => item.type === 'photo-log')
      const options = photoLogCustomList ? photoLogCustomList.options.map(option => {
        return {
          id: option.id,
          option: option.option,
          default: option.default
        }
      }) : [];
      return options;
    },
    //for filters of Counseling
    getFiltersAssociateStatus: (state) => {
      return state.filtersAssociateStatus;
    },
    getFiltersCounselingStatus: (state) => {
      return state.filtersCounselingStatus
    },
    getFiltersCounselingSeverity: (state) => {
      state.filtersCounselingSeverity=[]
      const customList = state.customList
      for(let custom of customList) {
        if (custom.type === "counseling-severity") {
          for (let opt of custom.options) {
            state.filtersCounselingSeverity.push(opt.option)
          }
        }
      }
      return state.filtersCounselingSeverity
    },
    getFiltersCounselingType: (state) => {
      const customList = state.customList.find(item => item.type == 'counseling-type')
      state.filtersCounselingType = customList ? customList.options.flatMap(item => item.option) : []
      return state.filtersCounselingType
    },
    getDateOfOccurrenceCounseling: (state) => {
      return state.dateOfOccurrenceCounseling
    },
    getDateSendCounseling: (state) => {
      return state.dateSendCounseling
    }, 
     //for filters of vehicle maintenance
    getFilterStatusVehicleMaintenance: (state) => {
      return state.filterStatusVehicleMaintenance
    },
    getFilterMaintStatusVehicleMaintenance: (state) => {
      return state.filterMaintStatusVehicleMaintenance
    },
    getFilterDateVehicleMaintenance: (state) => {
      return state.filterDateVehicleMaintenance
    },
    //filter of daily report
    getFilterTypeOfActivityDailyReport:(state) => {
      return state.filterTypeOfActivityDailyReport
    },
    getFilterRosterStatusDailyReport:(state) => {
      return state.filterRosterStatusDailyReport
    },
    getIpAddress:(state) => {
      return state.ipAddress;
    },
    getMutationNameMap:() => {
      return {
        CreateValueListItem: "Create Value List Item",
      };
    },
    getAssociateListFiltersForRDSForRDS:() => {
      return state.AssociateListFiltersForRDS
    },
    getVehicleListFiltersForRDS:() => {
      return state.VehicleListFiltersForRDS
    },
    // Custom List 
    existsInStore: (state, getters) => (value, type, returnValue = false) => {
      let customList = getters.getCustomListByType(type)
      if(!Object.keys(customList).length) return
      const existsOption = customList.options.some(item => item.option.toLowerCase() === value.toLowerCase())
      if(existsOption) return
      const order = !customList.options.length? 0 : customList.options[customList.options.length -1].order +1

      const inputOption = JSON.parse(JSON.stringify(state.customListOption))
      const input = {
        id: mixinMethods.generateUUID(),
        group: customList.group,
        order: order,
        option: value,
        pillColor: 'blue',
        statusType: 'Active (Neutral)',
        optionsCustomListsCustomListsId: customList.id,
        ...inputOption,
      }
      if(type === 'issue-type' || type === 'kudo-type'){
        input.driverReportSetting = 0
      }
      if (returnValue) {
        return input
      }
      customList.options.push(input)

    },
    changeStatusOptionCustomList: (state, getters) => (row, type) => {
      let customList = getters.getCustomListByType(type);
      if(!Object.keys(customList).length || !customList.options?.length) return;
      customList.options.find(option => {
        if(option.id === row.id){
          option.isEnabled = row.isEnabled;
        }
      })
    },
    //filters of vehicle statistics report
    getVehicleStatisticsFilters: (state) => (filterType) => {
      return state.vehicleStatisticsFilters[filterType]
    },
    //for filters of report Vehicle By Type
    getFiltersVehicleTypeReport: (state) => (filterType) => {
      return state.filtersVehicleTypeReport[filterType]
    },
    getTenantCoachingInfo: (state) => {
      return state.tenantCoachingInfo
    },
    getDaPerformanceData: (state) => {
      return state.daPerformanceData
    },
    getDaScore: (state) => {
      return state.daScore
    },
    //labels
    getLabelTypes:(state) => {
      let labelTypesList = state.labelTypes.map(obj => { 
        const {id, name, ...rest} = obj 
        return {id, name};
      })
      return labelTypesList
    },
    getAssociateLabels:(state) => {
      let associateLabelList = state.labelTypes.find(labelType => labelType.name === "Associates")
      if(!Object.keys(associateLabelList).length) return []
      associateLabelList = associateLabelList.labelList?.items?.map(obj => obj.label)
      return associateLabelList?.sort((a, b) => a.name.localeCompare(b.name));
    },
    getActiveAssociateLabels:(state) => {
      let associateLabelList = state.labelTypes.find(labelType => labelType.name === "Associates")
      if(!Object.keys(associateLabelList).length) return []
      associateLabelList = associateLabelList.labelList?.items?.filter(obj => obj.label.status == true).map(obj => obj.label)
      return associateLabelList?.sort((a, b) => a.name.localeCompare(b.name));
    },
    getVehicleLabels:(state) => {
      let vehicleLabelList = state.labelTypes.find(labelType => labelType.name === "Vehicles")
      if(!Object.keys(vehicleLabelList).length) return []
      vehicleLabelList = vehicleLabelList.labelList?.items?.map(obj => obj.label)
      return vehicleLabelList?.sort((a, b) => a.name.localeCompare(b.name));
    },
    getActiveVehicleLabels:(state) => {
      let vehicleLabelList = state.labelTypes.find(labelType => labelType.name === "Vehicles")
      if(!Object.keys(vehicleLabelList).length) return []
      vehicleLabelList = vehicleLabelList.labelList?.items?.filter(obj => obj.label.status == true).map(obj => obj.label)
      return vehicleLabelList?.sort((a, b) => a.name.localeCompare(b.name));
    },
    getUpdatingDailyRosterRoute: (state) => {
      return state.updatingDailyRosterRoute
    },
    isZohoCrmDisabled: (state) => {
      return state.systemList[0].isZohoCrmDisabled
    },
    // vehicle list
    getVehicleListStatusFilter:(state) => {
      return state.filtersVehicleList.status ? state.filtersVehicleList.status : []
    },
    getVehicleListTypeFilter:(state) => {
      return state.filtersVehicleList.vehicleType ? state.filtersVehicleList.vehicleType : []
    },
    getVehicleListLabelsFilter:(state) => {
      return state.filtersVehicleList.labels ? state.filtersVehicleList.labels : []
    },
    getVehicleListCompanyFilter:(state) => {
      return state.filtersVehicleList.company ? state.filtersVehicleList.company : []
    },
    getFiltersSearchType: (state) => {
      return state.filtersSearchType
    },
    getPerformanceImportsNotification: (state) => {
      return state.performanceImportsNotification;
    },
    getContainerData: (state) => {
      return state.containerData;
    },
    getMessengerTabs: (state) => {
      return state.messengerTabs
    },
    getNotesInUse: (state) => {
      return state.notesInUse
    }
  },
  mutations: {
    setSessionInfo(state, payload){     
      state.sessionInfo =  payload
    },
    setUserInfo(state, payload){     
      state.userInfo =  payload
    },
    setLastUserSession(state, payload){     
      state.lastUserSession =  payload
    },
    setTenantName(state, payload){
      state.userInfo.tenant.companyName = payload
    },
    setTimeZone(state, payload) {
      state.userInfo.tenant.timeZone= payload
    },
    setCompanyLogo(state, payload){     
      state.companyLogo =  payload
    },
    setSettingsView(state, view){
      state.settingsView = view
    },
    setMonitoringJobs(state, payload){
      state.monitoringJobs = payload
    },
    setValuelists(state, payload){
      var key = payload.key
      var items = payload.items
      var id = payload.id
      let valueListObj = {
        ...state.valuelists,
        [key]: {
          id,
          items
        }
      }
      state.valuelists = valueListObj
    },
    setCustomList(state, payload) {
      state.customList = payload
    },
    setCustomListOption (state, { type, option }) {
      const customList = this.getters.getCustomListByType(type)
      const index = customList.options.findIndex(item => item.id == option.id)
      if(index == -1) return

      state.customList = state.customList.map(item => {
        if(item.type == type) {
          item.options[index] = option
        }
        return item
      })
    },
    setFilterAssociateList(state, { filter, value } ) {
      state.filterAssociateList[filter] = value
    },

    setNotifications(state, payload){
      state.notifications = payload
    },
    addNotification(state, payload){
      state.notifications.unshift(payload)
    },
    setMessengerNotifications(state, payload){
      state.messengerNotifications = payload
    },
    addMessengerNotification(state, payload){
      console.log(payload)
      state.messengerNotifications.unshift(payload)
    },
    setMessengerStaff(state, payload){
      state.messengerStaff = payload
    },
    setStaffList(state, payload){
      state.staffList = payload
    },
    setSignUpCredentials(state, payload){
      state.signUpCredentials = payload
    },
    setNetradyneDateRange(state, payload){
      state.netradyneReportDateRange = [...payload]
    },
    setAssociateListFiltersForRDS: (state, data) => {
      state.AssociateListFiltersForRDS = data
    },
    setVehicleListFiltersForRDS: (state, data) => {
      state.VehicleListFiltersForRDS = data
    },
    setVehicleList(state, payload){
      state.vehicleList = [...payload]
    },
    setStatusChecked(state, payload) {
      state.tempStatusCheckedDAlist = payload;
    },
    setStatusCheckedForVehicle(state, payload) {
      state.tempStatusCheckedVehiclelist = payload;
    },
    setStatusCheckedForReports(state, payload) {
      state.tempStatusCheckedReports = {...state.tempStatusCheckedReports, ...payload};
    },
    setSupportAdministrator(state, payload) {
      state.supportAdministrator = payload
    },
    //for filters of Counseling
    setFiltersAssociateStatus(state, list) {
      state.filtersAssociateStatus = list;
    },
    setFiltersCounselingSeverity(state, list){
      state.filtersCounselingSeverity = list
    },
    setFiltersCounselingType(state, list){
      state.filtersCounselingType = list
    },
    setFiltersCounselingStatus(state, list){
      state.filtersCounselingStatus = list
    },
    setDateOfOccurrenceCounseling(state, value){
      state.dateOfOccurrenceCounseling = value
    },
    setDateSendCounseling(state, value){
      state.dateSendCounseling = value
    },
    //for filters of vehicle maintenance
    setFilterStatusVehicleMaintenance(state, list) {
      state.filterStatusVehicleMaintenance = list
    },
    setFilterMaintStatusVehicleMaintenance(state, list) {
      state.filterMaintStatusVehicleMaintenance = list
    },
    setFilterDateVehicleMaintenance(state, value){
      state.filterDateVehicleMaintenance = value
    },
    //filter of daily report
    setFilterTypeOfActivityDailyReport(state, value){
      state.filterTypeOfActivityDailyReport = value
    },
    setFilterRosterStatusDailyReport(state, value){
      state.filterRosterStatusDailyReport = value
    },
    setIsGeneratingCounselingFiles(state, value) {
      state.isGeneratingCounselingFiles = value;
    },
    setReadOnlyAccess: (state, value) => {
      state.readOnlyAccess = value
    },
    setTemporaryValueInStore(state, payload){
      state.valuelists[payload.storeId].items.push({
        value: payload.value
      })
    },
    //set filters of vehicle statistics report
    setVehicleStatisticsFilters: (state, { filterType, value }) => {
      state.vehicleStatisticsFilters[filterType] = value
    },
    //for filters of report Vehicle By Type
    setFiltersVehicleTypeReport: (state, { filterType, value }) => {
      state.filtersVehicleTypeReport[filterType] =  value
    },
    setTenantCoachingInfo: (state, value) => {
      state.tenantCoachingInfo = value
    },
    setDaPerformanceData: (state, value) => {
      state.daPerformanceData = value
    },
    setDaScore: (state, value) => {
      state.daScore = value
    },
    // for labels epic
    setLabelTypes(state, list) {
      state.labelTypes = list;
    },
    // for labels filters
    setLabelFiltersList( state, list ){
      state.labelFiltersList = list
    },
    setStatusFiltersList( state, list ){
      state.statusFiltersList = list 
    },
    setVehicleListFilters(state, { filter, value } ) {
      state.filtersVehicleList[filter] = value
    },
    //end of labels
    setTenantCoachingInfo: (state, value) => {
      state.tenantCoachingInfo = value
    },
    setDaPerformanceData: (state, value) => {
      state.daPerformanceData = value
    },
    setDaScore: (state, value) => {
      state.daScore = value
    },
    setUpdatingDailyRosterRoute: (state, value) => {
      state.updatingDailyRosterRoute = value
	},
    setSystemList: (state, value) => {
      state.systemList = value
    },
    setUpdateSystemList: (state, value) => {
      state.systemList = [value.data.updateSystem]
	  },
    setFiltersSearchType(state, list){
      state.filtersSearchType = list
    },
    addToSession(state, value) {
      state.messengerData.push(value);
      state.messengerData = state.messengerData.filter((message) => {
        const messageDate = new Date(message.startTime);
        const messageDateBeforeHour = new Date(messageDate.getTime());
        messageDateBeforeHour.setMinutes(messageDateBeforeHour.getMinutes() + 30);
        return messageDateBeforeHour > new Date();
      });
    },
    addPerformanceImportsNotification(state, payload){
      state.performanceImportsNotification.push(payload)
    },
    setContainerData(state, payload) {
      state.containerData = payload
    },
    setMessengerTabs: (state, value) => {
      state.messengerTabs = value
    },
    setNotesInUse: (state,  { type, value }) => {
      state.notesInUse[type] = value
    },
  },
  actions: {
    // async monitorJobsStatus(context){
    //   console.log("monitoring job status'")
    //   context.commit('setMonitoringJobs', true)
    //   var timer = setInterval(async ()=>{
    //     var jobs = await checkJobsStatus()        
    //     context.commit('setJobs', jobs)
    //     jobs.forEach(job =>{
    //         let payload = {
    //             importId: job.jobId,
    //             jobId: job.id,
    //             job: job
    //         }
    //         context.commit('setImportJobs', payload)
    //     })
    //     var jobsFiltered = jobs.filter(job=>job.jobStatus != "SUCCEEDED")
    //     if( jobs.length == 0){
    //       context.commit('setMonitoringJobs', false)
    //       clearInterval(timer)
    //     }
    //   }, 5000)
    // },
    clearVehicleListFiltersForRDS: ( { commit }) => commit('setVehicleListFiltersForRDS', null),
    clearAssociateListFiltersForRDS: ( { commit }) => commit('setAssociateListFiltersForRDS', null),
    async markMessagesAsRead(context, payload){
      let notifications = context.state.messengerNotifications;
      let { messageUnread = 0 } = payload;
      if(notifications > 0) {
        context.commit ('setMessengerNotifications', notifications - messageUnread )
      }
    },
    async marNewkMessageRecieved(context) {
      let notifications = context.state.messengerNotifications;
      context.commit( 'setMessengerNotifications', notifications + 1 );
    },
    setCustomListOption: async ( { commit }, payload) => commit('setCustomListOption', payload),
    setVehicleListFilters: ( { commit }, payload) => commit('setVehicleListFilters', payload),
    async loadLabelTypes({ commit, state, event }) {
        try{
          const input = {
            group: state.userInfo.tenant.group
          } 

          let labelTypeList = await mixinMethods.gLoadListAll(labelTypeByNameAndGroup, input, "labelTypeByNameAndGroup");
          commit('setLabelTypes', labelTypeList);
        }catch(e){
            console.error(e)
      }
    },
    loadSystemList({ commit }) {
      new Promise((resolve, reject) => {
        mixinMethods
          .gLoadListAll(listSystems, {}, "listSystems")
          .then((data) => {
            commit("setSystemList", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateSystemList({ commit, state }, payload) {
      const id = state.systemList[0].id;
      const input = { id, ...payload };
      new Promise((resolve, reject) => {
        mixinMethods
          .api(updateSystem, { input })
          .then((data) => {
            commit("setUpdateSystemList", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setFilterAssociateList: ( { commit }, payload) => commit('setFilterAssociateList', payload),

    setNotesInUse: ( { commit }, payload) => commit('setNotesInUse', payload)
  },
  modules: {
    subscriptionStore: require('./subscriptionModule').subscriptionStore
  },
  plugins: [
    createPersistedState({
      paths: ['userInfo','lastUserSession','jobs', 'signUpCredentials', 'netradyneReportDateRange', 'customList', 'filtersVehicleList', 'filterAssociateList','VehicleListFiltersForRDS','AssociateListFiltersForRDS']
    })
  ]
})
